import React from "react";

const useDebounced = <T>(
  value: T,
  debounceDelay = 500,
  onChange?: (value: T) => void
) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const lastUpdate = React.useRef<number>(Date.now());
  const setter = React.useRef<React.Dispatch<React.SetStateAction<T>>>();
  setter.current = setDebouncedValue;

  // debounce handler
  React.useEffect(() => {
    let cancel = false;
    setTimeout(() => {
      if (!cancel || Date.now() - lastUpdate.current > debounceDelay * 2) {
        lastUpdate.current = Date.now();
        onChange?.(value);
        setter.current!(value);
      }
    }, debounceDelay);
    return () => {
      cancel = true;
    };
  }, [value, debounceDelay]);

  // call when component unmounts
  React.useEffect(() => {
    return () => {
      onChange?.(value);
    };
  }, []);

  return {
    value: debouncedValue,
    skipDebounce: (newValue?: T) => setDebouncedValue(newValue ?? value),
  };
};

export default useDebounced;
