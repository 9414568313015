import { SimpleDate } from "@idot-digital/calendar-api";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import { useQuery } from "@tanstack/react-query";

const StatsServer = {
  async getCustomerStay(from: SimpleDate, to: SimpleDate) {
    const res = await Server.get<
      {
        customers: string;
        returned: string;
        employeeid: ID;
      }[]
    >("stats/customerStay/:from/:to", {
      params: {
        from,
        to,
      },
      errorMessage: "Fehler beim Laden der Kundenrücklaufstatistik",
    });

    return res.map((r) => ({
      total: parseInt(r.customers),
      returned: parseInt(r.returned),
      employeeid: r.employeeid,
    }));
  },

  async getOnlineBooked(from: SimpleDate, to: SimpleDate) {
    const res = await Server.get<{
      online: string;
      manual: string;
    }>("stats/onlineBooked/:from/:to", {
      params: {
        from,
        to,
      },
      errorMessage: "Fehler beim Laden der Onlinebuchungsstatistik",
    });

    return {
      online: parseInt(res.online),
      manual: parseInt(res.manual),
    };
  },

  async getCancellations(from: SimpleDate, to: SimpleDate) {
    const res = await Server.get<{
      total: string;
      cancelled: string;
    }>("stats/cancelled/:from/:to", {
      params: {
        from,
        to,
      },
      errorMessage: "Fehler beim Laden der Stornierungsstatistik",
    });

    return {
      total: parseInt(res.total),
      cancelled: parseInt(res.cancelled),
    };
  },

  async getRevenue(from: SimpleDate, to: SimpleDate) {
    const res = await Server.get<
      {
        employeeid: ID;
        revenue: string;
      }[]
    >("stats/revenue/:from/:to", {
      params: {
        from,
        to,
      },
      errorMessage: "Fehler beim Laden der Umsatzstatistik",
    });

    return res.map((r) => ({
      ...r,
      revenue: parseInt(r.revenue),
    }));
  },

  async getEmployeeStats(from: SimpleDate, to: SimpleDate) {
    const res = await Server.get<
      {
        employeeid: ID;
        revenue: number;
        service_factor: number;
        appointment_count: number;
      }[]
    >("stats/employeestats/:from/:to", {
      params: {
        from,
        to,
      },
      errorMessage: "Fehler beim Laden der Mitarbeiterstatistik",
    });

    return res;
  },

  useCancellations(from: SimpleDate, to: SimpleDate) {
    return useQuery({
      queryKey: ["stats", "cancellations", from, to],
      queryFn: () => StatsServer.getCancellations(from, to),
    });
  },
  useCustomerStay(from: SimpleDate, to: SimpleDate) {
    return useQuery({
      queryKey: ["stats", "customer_stay", from, to],
      queryFn: () => StatsServer.getCustomerStay(from, to),
    });
  },
  useOnlineBooked(from: SimpleDate, to: SimpleDate) {
    return useQuery({
      queryKey: ["stats", "online_booked", from, to],
      queryFn: () => StatsServer.getOnlineBooked(from, to),
    });
  },
  useRevenue(from: SimpleDate, to: SimpleDate) {
    return useQuery({
      queryKey: ["stats", "revenue", from, to],
      queryFn: () => StatsServer.getRevenue(from, to),
    });
  },
  useEmployeeStats(from: SimpleDate, to: SimpleDate) {
    return useQuery({
      queryKey: ["stats", "employee", from, to],
      queryFn: () => StatsServer.getEmployeeStats(from, to),
    });
  },

  getFilterDuration() {
    const filter = sessionStorage.getItem("statsFilterDuration");
    if (!filter) {
      return {
        start: SimpleDate.now().setHours(0, 0),
        end: SimpleDate.now().setHours(23, 59),
      };
    }

    const parsed = JSON.parse(filter);
    return {
      start: SimpleDate.importInt(parsed.start),
      end: SimpleDate.importInt(parsed.end),
    };
  },
  setCurrentFilterDuration(duration: { start: SimpleDate; end: SimpleDate }) {
    sessionStorage.setItem(
      "statsFilterDuration",
      JSON.stringify({
        start: duration.start.exportInt(),
        end: duration.end.exportInt(),
      })
    );
  },
};

export default StatsServer;
