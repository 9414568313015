import React from "react";

import { Add, Edit, Error, Save } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Input,
  Button,
  Switch,
  FormControlLabel,
  Popover,
  Divider,
} from "@mui/material";

import { ID, Styles } from "../../../../Types";
import CustomerSearch from "./CustomerSearch";
import { useSaveSettings } from "../../../Settings/SaveSettingsProvider";
import { useAppointmentPopup } from "./AppointmentPopupContext";
import AppointmentEditCustomer from "./AppointmentEditCustomer";
import AppointmentFunctions from "../../../Server/Appointments/AppointmentFunctions";
import { Appointment } from "../../../Server/Appointments/AppointmentTypes";
import AppointmentServer from "../../../Server/Appointments/AppointmentServer";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";
import AppointmentPopupDurations from "./AppointmentPopupDurations";
import AppointmentServiceSelector from "./AppointmentServiceSelector";
import AppointmentPricePicker from "./AppointmentPricePicker";
import { useQuery } from "@tanstack/react-query";
import useDebounced from "../../../../Hooks/Debouncer";
import ServiceServer from "../../../Server/Services/ServiceServer";
import { deepCopy } from "../../../../Functions/ObjectFunctions";

export interface AppointmentPopupEditProps {
  open: boolean;
  queueSave: (saveFunction: () => void) => void;
  pickTime: () => void;
  onExited: () => void;
}

export const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(4),
  },
  innerWrapper: {
    marginRight: (theme) => theme.spacing(4),
    display: "flex",
    flexDirection: "row",

    "> *": {
      display: "flex",
      flexDirection: "column",

      alignItems: "flex-start",

      "> *": {
        margin: (theme) => theme.spacing(1, 0),
      },
    },
  },
  select: {
    width: "85%",
    margin: (theme) => theme.spacing(1, 0),
  },
  customerWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    margin: 0,
  },
  label: {
    color: (theme) => theme.palette.text.primary,
  },
  marginLeft: {
    float: "right",
  },
  getTimesWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};

export default function AppointmentPopupEdit({
  open,
  queueSave,
  pickTime,
  onExited,
}: AppointmentPopupEditProps) {
  const { useSetChanged, checkForChanges } = useSaveSettings();

  const {
    onClose,
    onSave,
    setEdit,

    mounted,
    update,

    appointment,
    setAppointmentData,
    setAppointmentDurations,

    resetDurations,
    durations,
    defaultPrice,
    mainEmployee,
    main_services,
    additional_services,
    createdBy,
    customer,
    appointmentValid,
    timeError,
    manual_mode,
  } = useAppointmentPopup();

  const { data: services = [] } = ServiceServer.useAll();
  const { data: employees = [] } = EmployeeServer.useAll();

  const [customerOpen, setCustomerOpen] = React.useState(false);

  const manual_mode_switch = React.useRef<HTMLButtonElement>(null);
  const [manual_mode_warning_open, setManual_mode_warning_open] =
    React.useState(false);

  const [initialAppointment, setInitialAppointment] = React.useState(
    AppointmentFunctions.copy(appointment)
  );

  const setInitialAppointmentData = React.useCallback(
    (
      data: Partial<Appointment>,
      callback?: (appointment: Appointment) => void
    ) => {
      setInitialAppointment((appointment) => {
        const newAppointment = AppointmentFunctions.copy({
          ...appointment,
          ...data,
        });

        callback?.(newAppointment);

        return newAppointment;
      });
    },
    []
  );

  const changed = React.useMemo(
    () => !AppointmentFunctions.isEqual(appointment, initialAppointment),
    [appointment, initialAppointment]
  );

  // Debouncer for conflict fetching
  const debounceFetchConflicts = React.useRef(0);

  const { value: debouncedDurations } = useDebounced(durations);

  const {
    data: appointmentConflict = {
      workhours: false,
      appointments: [],
    },
    isSuccess: appointmentConflictLoaded,
  } = useQuery({
    queryKey: [
      "conflict",
      ...debouncedDurations.map((d) => ({
        ...d,
        start: d.start.exportInt(),
        end: d.end.exportInt(),
      })),
    ],
    queryFn: async () => {
      const now = Date.now();
      debounceFetchConflicts.current = now;

      const conflicts = await AppointmentServer.conflicts(debouncedDurations);

      // Check if request is still valid
      return debounceFetchConflicts.current === now
        ? conflicts
        : {
            workhours: false,
            appointments: [],
          };
    },
    enabled:
      debouncedDurations.length > 0 &&
      !timeError &&
      debouncedDurations.every(
        (d) => d.employeeid !== -1 && d.start.exportInt() < d.end.exportInt()
      ),
  });

  const filteredConflicts = React.useMemo(() => {
    if (!appointmentConflict) return [];
    return appointmentConflict.appointments
      .filter((conflict) => conflict !== appointment.id)
      .concat(appointmentConflict.workhours ? [-1] : []);
  }, [appointmentConflict, appointment.id]);

  const error = React.useMemo(
    () => !appointmentValid || timeError || !!filteredConflicts.length,
    [appointmentValid, timeError, filteredConflicts]
  );

  const save = () => {
    queueSave(async (sendMail?: boolean) => {
      if (appointmentValid) {
        const id = await AppointmentServer[
          appointment.id !== -1 ? "update" : "create"
        ](appointment, sendMail, initialAppointment.durations);
        if (!mounted.current) return;
        onSave?.();
        resetInitial();
        if (!id) setEdit?.(false);
        else {
          setAppointmentData({ id });
          onClose?.();
        }
      }
    });
  };

  const close = async () => {
    const open = !(await checkForChanges());
    if (!open) onClose?.();
  };

  const resetInitial = React.useCallback(() => {
    setInitialAppointment(AppointmentFunctions.copy(appointment));
  }, [appointment]);

  useSetChanged({ changed, error: !!error }, { save });

  React.useEffect(
    () => resetInitial(),
    // Only update initial on edit when update is queued
    // eslint-disable-next-line
    [update]
  );

  // Keep internal states synced up -- prevent changed from being set
  React.useEffect(() => {
    setInitialAppointmentData({
      created_by: appointment.created_by,
      created_at: appointment.created_at,
    });
  }, [
    setInitialAppointmentData,
    appointment.created_by,
    appointment.created_at,
  ]);

  // set price differences for services
  // TODO: unfinished feature: get last price differnce for customer + service
  // React.useEffect(() => {
  //   if (!appointment.customerid || appointment.services.length === 0) return;
  //   (async () => {
  //     const priceDifference = await Promise.all(
  //       appointment.services.map(async ({ serviceid }) =>
  //         serviceid
  //           ? {
  //               priceDifference: await CustomerServer.getLastPriceDifference(
  //                 appointment.customerid!,
  //                 serviceid
  //               ),
  //               serviceid,
  //             }
  //           : { serviceid: -1, priceDifference: 0 }
  //       )
  //     );
  //     if (!mounted.current) return;
  //     console.log("set price differences", {
  //       customerid: appointment.customerid,
  //       services: appointment.services,
  //     });
  //     if (
  //       appointment.services.every(
  //         (s) =>
  //           s.price_difference !== 0 &&
  //           priceDifference.find(({ serviceid }) => serviceid === s.serviceid)
  //       )
  //     )
  //       return;
  //     console.log("actually set price differences");
  //     console.log(priceDifference);
  //     setAppointmentData({
  //       services: appointment.services.map((service) => ({
  //         ...service,
  //         price_difference:
  //           service.price_difference ||
  //           priceDifference.find(
  //             ({ serviceid }) => service.serviceid === serviceid
  //           )?.priceDifference ||
  //           0,
  //       })),
  //     });
  //   })();
  // }, [appointment.customerid, appointment.services]);

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullWidth
        maxWidth="md"
        TransitionProps={{ onExited }}
      >
        <DialogContent sx={styles.wrapper}>
          <Box sx={styles.innerWrapper}>
            <Box width="60%">
              <Box sx={styles.customerWrapper}>
                <Box sx={styles.select}>
                  <CustomerSearch
                    customer={customer}
                    onChange={(customer, attributes) =>
                      mounted.current &&
                      setAppointmentData({
                        customerid: customer?.id || null,
                        attributes: attributes || undefined,
                      })
                    }
                    fullWidth
                  />
                </Box>
                <IconButton
                  onClick={() => setCustomerOpen(true)}
                  sx={{
                    height: (theme) => theme.spacing(6),
                    width: (theme) => theme.spacing(6),
                  }}
                >
                  {customer ? <Edit /> : <Add />}
                </IconButton>
              </Box>
              <AppointmentServiceSelector
                serviceids={main_services.map((s) => s.id)}
                onChange={(ids) => {
                  const all_service_ids = ids.concat(
                    additional_services.map((s) => s.id)
                  );
                  if (!manual_mode) {
                    const addedIDs = ids.filter(
                      (id) =>
                        !appointment.tasks.some(
                          (t) => t.serviceid === id && t.taskid
                        )
                    );
                    setAppointmentData({
                      tasks: appointment.tasks
                        .filter(
                          (t) =>
                            !t.serviceid ||
                            all_service_ids.includes(t.serviceid)
                        )
                        .concat(
                          addedIDs.map((id) => ({
                            employeeid: -1,
                            factor: -1,
                            price_difference: 0,
                            serviceid: id,
                            task_price: 0,
                            taskid: -1,
                          }))
                        ),
                    });
                    resetDurations();
                  } else {
                    const allTasks = services
                      .filter((s) => all_service_ids.includes(s.id))
                      .flatMap((s) => s.tasks);
                    const addedTasks = allTasks.filter(
                      (t) => !appointment.tasks.some((at) => at.taskid === t.id)
                    );
                    const removedTaskIDs = main_services
                      .filter((s) => !ids.includes(s.id))
                      .flatMap((s) => s.tasks.map((t) => t.id));
                    setAppointmentDurations(
                      durations.map((d) => {
                        if (d.taskid && removedTaskIDs.includes(d.taskid)) {
                          return {
                            ...d,
                            taskid: null,
                          };
                        }
                        return d;
                      })
                    );
                    setAppointmentData({
                      tasks: appointment.tasks
                        .filter(
                          (t) => !t.taskid || !removedTaskIDs.includes(t.taskid)
                        )
                        .concat(
                          addedTasks.map((t) => ({
                            employeeid: -1,
                            factor: t.factor,
                            price_difference: 0,
                            serviceid: t.serviceid,
                            task_price: 0,
                            taskid: t.id,
                          }))
                        ),
                    });
                  }
                }}
                mainEmployee={mainEmployee?.id}
                startTime={durations[0]?.start}
                additional_services={false}
              />
              <AppointmentServiceSelector
                serviceids={additional_services.map((s) => s.id)}
                onChange={(ids) => {
                  const all_service_ids = ids.concat(
                    main_services.map((s) => s.id)
                  );
                  if (!manual_mode) {
                    const addedIDs = ids.filter(
                      (id) =>
                        !appointment.tasks.some(
                          (t) => t.serviceid === id && t.taskid
                        )
                    );
                    setAppointmentData({
                      tasks: appointment.tasks
                        .filter(
                          (t) =>
                            !t.serviceid ||
                            all_service_ids.includes(t.serviceid)
                        )
                        .concat(
                          addedIDs.map((id) => ({
                            employeeid: null,
                            factor: -1,
                            price_difference: 0,
                            serviceid: id,
                            task_price: 0,
                            taskid: -1,
                          }))
                        ),
                    });
                    resetDurations();
                  } else {
                    const allTasks = services
                      .filter((s) => all_service_ids.includes(s.id))
                      .flatMap((s) => s.tasks);
                    const addedTasks = allTasks.filter(
                      (t) => !appointment.tasks.some((at) => at.taskid === t.id)
                    );
                    const removedTaskIDs = additional_services
                      .filter((s) => !ids.includes(s.id))
                      .flatMap((s) => s.tasks.map((t) => t.id));
                    setAppointmentDurations(
                      durations.map((d) => {
                        if (d.taskid && removedTaskIDs.includes(d.taskid)) {
                          return {
                            ...d,
                            taskid: null,
                          };
                        }
                        return d;
                      })
                    );
                    console.log("service change", {
                      allTasks,
                      addedTasks,
                      removedTaskIDs,
                    });
                    setAppointmentData({
                      tasks: appointment.tasks
                        .filter(
                          (t) => !t.taskid || !removedTaskIDs.includes(t.taskid)
                        )
                        .concat(
                          addedTasks.map((t) => ({
                            employeeid: null,
                            factor: t.factor,
                            price_difference: 0,
                            serviceid: t.serviceid,
                            task_price: 0,
                            taskid: t.id,
                          }))
                        ),
                    });
                  }
                }}
                mainEmployee={mainEmployee?.id}
                startTime={durations[0]?.start}
                additional_services={true}
              />

              <FormControl variant="standard" sx={styles.select}>
                <InputLabel sx={styles.label}>Mitarbeiter</InputLabel>
                <Select
                  value={mainEmployee?.id || appointment.main_employeeid || -1}
                  onChange={(e) => {
                    resetDurations();
                    setAppointmentData({
                      main_employeeid: e.target.value as ID,
                    });
                  }}
                  fullWidth
                >
                  <MenuItem value={-1} key={-1}>
                    <em>Keine Auswahl</em>
                  </MenuItem>
                  {employees.map((employee) => (
                    <MenuItem
                      value={employee.id}
                      key={employee.id}
                      disabled={
                        // check if the selected service is not offered by this employee
                        !!main_services[0] &&
                        !main_services[0].tasks.some((t) =>
                          t.allowed_employees.some(
                            (e) => e.employeeid === employee.id
                          )
                        )
                      }
                    >
                      {employee.shortName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {appointment.online ? (
                <Typography>Online gebucht</Typography>
              ) : (
                <FormControl variant="standard" sx={styles.select}>
                  <InputLabel sx={styles.label}>erstellt von</InputLabel>
                  <Input
                    value={createdBy?.displayname || "gelöschter Mitarbeiter"}
                    disabled
                  />
                </FormControl>
              )}

              <Divider
                orientation="horizontal"
                sx={{ width: "85%", borderStyle: "dashed" }}
              >
                <Typography variant="overline">Preis</Typography>
              </Divider>
              <AppointmentPricePicker
                main_services={main_services}
                additional_services={additional_services}
                tasks={appointment.tasks}
                durations={durations}
                onChange={(tasks) => setAppointmentData({ tasks })}
                toggleDiscount={() => {
                  if (
                    defaultPrice.discountid !== null &&
                    defaultPrice.discountid !== -1
                  )
                    setAppointmentData(
                      appointment.discountid
                        ? {
                            discountid: null,
                            price: defaultPrice.default_price,
                          }
                        : {
                            discountid: defaultPrice.discountid,
                            price: defaultPrice.discounted_price,
                          }
                    );
                }}
              />
            </Box>

            <Box width="40%" sx={styles.timeInputWrapper}>
              <IconButton
                onClick={save}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.primary.main,
                }}
                size="large"
                disabled={Boolean(
                  !changed || error || !appointmentConflictLoaded
                )}
              >
                <Save fontSize="large" />
              </IconButton>

              <AppointmentPopupDurations
                onChange={(durations) => setAppointmentDurations(durations)}
                value={durations}
                services={main_services.concat(additional_services)}
              />
              <Box sx={styles.getTimesWrapper}>
                <Button variant="contained" onClick={pickTime}>
                  Zeit auswählen
                </Button>
              </Box>
              {Boolean(filteredConflicts.length) && (
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                >
                  <Error color="error" fontSize="large" />
                  <Typography color="error" textAlign="left">
                    Diese Zeiten kollidieren mit anderen Terminen
                  </Typography>
                </Box>
              )}
              <Box flexGrow={1} />
              <FormControlLabel
                control={
                  <Switch
                    checked={manual_mode}
                    onChange={() => setManual_mode_warning_open(true)}
                  />
                }
                label="Manueller Modus"
                sx={{
                  alignSelf: "center",
                }}
                ref={manual_mode_switch}
              />
              <Popover
                open={manual_mode_warning_open}
                onClose={() => setManual_mode_warning_open(false)}
                anchorEl={manual_mode_switch.current}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <Box padding={2}>
                  {manual_mode ? (
                    <>
                      <Typography>
                        Sind Sie sicher, dass Sie den manuellen Modus
                        deaktivieren wollen?
                      </Typography>
                      <Typography>
                        Invalide Kombinationen werden dann entfernt.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography>
                        Sind Sie sicher, dass Sie den manuellen Modus aktivieren
                        wollen?
                      </Typography>
                      <Typography>
                        emmasy wird Ihre Eingaben dann nicht mehr beschränken.
                      </Typography>
                    </>
                  )}
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    gap={1}
                    marginTop={1}
                  >
                    <Button
                      onClick={() => setManual_mode_warning_open(false)}
                      variant="contained"
                    >
                      Abbrechen
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setManual_mode_warning_open(false);
                        setAppointmentData({ manual_mode: !manual_mode });
                      }}
                    >
                      Bestätigen
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <AppointmentEditCustomer
        open={customerOpen}
        onClose={() => setCustomerOpen(false)}
        callback={async (customerid, attributes) => {
          // directly save attributes
          if (
            initialAppointment.id !== -1 &&
            initialAppointment.main_employeeid
          ) {
            // only update attributes if appointment exists
            setInitialAppointmentData({ attributes });
            await AppointmentServer.update(
              {
                ...initialAppointment,
                attributes,
              },
              false
            );
            if (!mounted.current) return;
          }

          setCustomerOpen(false);
          onSave?.();
          setAppointmentData({ customerid, attributes });
        }}
      />
    </>
  );
}
