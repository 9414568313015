import { Box, BoxProps } from "@mui/material";
import { EqualityCheck, SimpleDate } from "@idot-digital/calendar-api";
import { Styles } from "../../Types";
import TimePeriodPicker from "../Settings/Employees/TimePeriodPicker";
import CustomToggleButton from "./CustomToggleButton";
import { ActivityLogType } from "../Server/ActivityLog/ActivityLogTypes";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import PercentIcon from "@mui/icons-material/Percent";
import CategoryIcon from "@mui/icons-material/Category";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarViewMonth";
import WorkOutline from "@mui/icons-material/WorkOutline";
import {
  CurrencyExchange,
  Euro,
  Percent,
  ShoppingBagOutlined,
} from "@mui/icons-material";

export interface ActiveSortingProps extends Omit<BoxProps, "onChange"> {
  onChange: (filter: ActivityFeedSorting) => void;
  value: ActivityFeedSorting;
}

export interface ActivityFeedSorting {
  from: SimpleDate;
  to: SimpleDate;
  type: ActivityLogType;
}

export interface ActivityFeedButtonSelections {
  label: string;
  type: ActivityLogType;
  icon: JSX.Element;
}

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonWrapper: {
    display: "inline-flex",
    flexDirection: "column",
  },
  allButton: {
    minWidth: "20rem",
    marginTop: (theme) => theme.spacing(4),
    padding: (theme) => theme.spacing(1, 2),
  },
  button: {
    minWidth: "20rem",
    marginTop: (theme) => theme.spacing(1),
    ".MuiButton-startIcon": {
      position: "absolute",
      left: 8,
    },
  },
};

const possibleButtonSelections: ActivityFeedButtonSelections[] = [
  {
    label: "Terminänderungen",
    type: ActivityLogType.bookings,
    icon: <CalendarMonthIcon />,
  },
  {
    label: "Kunden Terminstornierungen",
    type: ActivityLogType.cancel,
    icon: <HighlightOffIcon />,
  },
  {
    label: "Kategorieänderungen",
    type: ActivityLogType.categories,
    icon: <CategoryIcon />,
  },
  {
    label: "Dienstleistungsänderungen",
    type: ActivityLogType.services,
    icon: <ContentCutIcon />,
  },
  {
    label: "Rabattänderungen",
    type: ActivityLogType.discount,
    icon: <PercentIcon />,
  },
  {
    label: "Nachträgliche Arbeitszeitenänderungen",
    type: ActivityLogType.actual_workhours,
    icon: <WorkOutline />,
  },
  {
    label: "Verkäufe",
    type: ActivityLogType.purchases,
    icon: <ShoppingBagOutlined />,
  },
  {
    label: "Gutscheine",
    type: ActivityLogType.coupons,
    icon: <Percent />,
  },
  {
    label: "Reklamationen",
    type: ActivityLogType.refund,
    icon: <CurrencyExchange />,
  },
  {
    label: "Zahlungen",
    type: ActivityLogType.appointment_payment,
    icon: <Euro />,
  },
];

export default function ActivitySorting(props: ActiveSortingProps) {
  const { onChange, value, ...rest } = props;

  const handleChange = (data: Partial<ActivityFeedSorting>) => {
    onChange({ ...value, ...data });
  };

  return (
    <Box {...rest}>
      <Box sx={styles.wrapper}>
        <TimePeriodPicker
          period={{
            start: value.from,
            end: value.to,
          }}
          onChange={(period) => {
            const start = period.start.toDate();
            start.setHours(0, 0, 0, 0);
            const end = period.end.toDate();
            end.setHours(23, 59, 59, 999);
            handleChange({
              from: SimpleDate.fromDate(start),
              to: SimpleDate.fromDate(end),
            });
          }}
          error={value.from.isEqual(value.to) !== EqualityCheck.later}
          variant="vertical"
        />
        <Box sx={styles.buttonWrapper}>
          {possibleButtonSelections.map(({ label, icon, type }, index) => (
            <CustomToggleButton
              checked={value.type === type}
              onChange={() => handleChange({ type })}
              sx={styles.button}
              key={index}
              startIcon={icon}
            >
              {label}
            </CustomToggleButton>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
