import { deepCopy, isEqual } from "../../../Functions/ObjectFunctions";
import { Service, Task } from "./ServiceTypes";
import TaskHelper from "./Tasks/TaskHelper";

const ServiceFunctions = {
  isEqual(service1: Service, service2: Service) {
    return isEqual(service1, service2);
  },

  isValid(service: Service):
    | {
        valid: true;
        error?: undefined;
      }
    | { valid: false; error: string } {
    if (service.tasks.length === 0)
      return { valid: false, error: "Keine Tasks angelegt" };
    for (const task of service.tasks) {
      if (TaskHelper.hasInvalidDurations(task))
        return { valid: false, error: "Task mit unmöglichen Zeiten" };
      if (!task.name) return { valid: false, error: "Task ohne Name" };
      if (task.allowed_employees.some((e) => !e.price))
        return { valid: false, error: "Mitarbeiter ohne Preis" };
      if (isNaN(task.factor)) return { valid: false, error: "Kein Faktor" };
      if (task.preparation_time > 99 || task.follow_up_time > 99)
        return {
          valid: false,
          error: "Vorbereitungs- oder Nachbereitungszeit zu lang",
        };
    }
    if (!service.name) return { valid: false, error: "Fehlender Name" };
    if (service.categoryid <= 0)
      return { valid: false, error: "Keine Kategorie" };
    return { valid: true };
  },

  copy(service: Service) {
    return deepCopy(service) as Service;
  },

  copyTasks(tasks: Task[]) {
    return deepCopy(tasks) as Task[];
  },
};

export default ServiceFunctions;
