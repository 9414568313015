import { decodeToken } from "react-jwt";
import config from "../../../config";
import Server from "../Generic/GenericServer";
import { setJwt } from "../jwt";
import { JWTPayload, ListAccount } from "./AccountTypes";
import { useQuery } from "@tanstack/react-query";

const AccountServer = {
  async login(username: string, password: string) {
    const jwt = await Server.post<string>("/login", {
      body: {
        username,
        password,
      },
      root: config.login_server_link,
      dontDisplayError: true,
    });
    const payload = decodeJWT(jwt);
    setJwt(jwt);
    return payload;
  },

  async checkPin(username: string, pin: string) {
    const res = await Server.post<
      string | "Invalid pin" | "User not found" | "Forbidden"
    >("/pin", {
      body: {
        username,
        pin,
      },
      root: config.login_server_link,
      dontDisplayError: true,
    });
    console.log(res);
    const ok =
      Boolean(res) &&
      res !== "Invalid pin" &&
      res !== "User not found" &&
      res !== "Forbidden";
    return {
      ok,
      jwt: ok ? res : undefined,
    };
  },

  list() {
    return Server.get<ListAccount[]>("/user", {
      root: config.login_server_link,
      errorMessage: "Fehler beim Laden der Benutzerliste",
    });
  },

  async get(username: string) {
    return Server.get<ListAccount>("/user/:username", {
      root: config.login_server_link,
      params: {
        username,
      },
      errorMessage: 'Fehler beim Laden des Benutzers "' + username + '"',
    });
  },

  use(username: string | undefined | null) {
    return useQuery({
      queryKey: ["employee", "details", username],
      queryFn: () => (username ? AccountServer.get(username) : null),
      enabled: !!username,
    });
  },
  useAll() {
    return useQuery({
      queryKey: ["employee", "all"],
      queryFn: AccountServer.list,
    });
  },
};

export function decodeJWT(jwtString: string): JWTPayload | null {
  const payload = decodeToken(jwtString) as null;
  return payload || null;
}

export default AccountServer;
