import { useQueries, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../queryClient";
import { ID } from "../../../Types";
import Server from "../Generic/GenericServer";
import { PartialPick } from "../Generic/GenericTypes";
import { Service, Task } from "./ServiceTypes";

const ServiceServer = {
  async get(id: ID) {
    if (id === -1) return null;
    return Server.get<Service & { enabled: boolean }>("/services/:id", {
      params: {
        id,
      },
      errorMessage: "Fehler beim Laden des Services mit id " + id,
    });
  },

  async list(cursor?: ID) {
    return Server.get<Service[]>("/services", {
      errorMessage: "Fehler beim Laden der Services",
    });
  },

  async create(
    service: PartialPick<Omit<Service, "tasks">, "id"> & {
      tasks: PartialPick<Task, "id">[];
    }
  ) {
    for (const task of service.tasks) {
      if (task.id === -1) delete task.id;
    }
    const id = parseInt(
      await Server.post<string>("/services", {
        body: service,
        errorMessage: "Fehler beim Erstellen des Services",
      })
    );

    queryClient.invalidateQueries({
      queryKey: ["service"],
      exact: false,
    });

    return id;
  },

  async update(
    service: Omit<Service, "tasks"> & { tasks: PartialPick<Task, "id">[] }
  ) {
    for (const task of service.tasks) {
      if (task.id && task.id <= -1) delete task.id;
    }
    await Server.patch<void>("/services/:id", {
      params: {
        id: service.id,
      },
      body: service,
      errorMessage:
        "Fehler beim Aktualisieren des Services mit id " + service.id,
    });

    queryClient.invalidateQueries({
      queryKey: ["service"],
      exact: false,
    });
    // can change durations of appointments since task might be deleted
    queryClient.invalidateQueries({
      queryKey: ["appointment"],
      exact: false,
    });
  },

  async delete(service: Pick<Service, "id" | "categoryid">) {
    await Server.delete<void>("/services/:id", {
      params: {
        id: service.id,
      },
      errorMessage: "Fehler beim Löschen des Services mit id " + service.id,
    });

    queryClient.invalidateQueries({
      queryKey: ["service"],
      exact: false,
    });
    queryClient.invalidateQueries({
      queryKey: ["category"],
      exact: false,
    });
    // can change durations of appointments since task might be deleted
    queryClient.invalidateQueries({
      queryKey: ["appointment"],
      exact: false,
    });
  },

  use(
    id: ID | undefined | null,
    options?: {
      enabled?: boolean;
    }
  ) {
    return useQuery({
      queryKey: ["service", id],
      queryFn: () => ServiceServer.get(id!),
      enabled:
        id !== undefined &&
        id !== null &&
        id !== -1 &&
        (options?.enabled ?? true),
    });
  },

  useMultiple(ids: ID[], options?: { enabled?: boolean }[]) {
    return useQueries({
      queries: ids.map((id, i) => ({
        queryKey: ["service", id],
        queryFn: () => ServiceServer.get(id),
        enabled: id !== -1 && (options?.[i]?.enabled ?? true),
        ...options?.[i],
      })),
    });
  },

  useAll() {
    return useQuery({
      queryKey: ["service"],
      queryFn: () => ServiceServer.list(),
    });
  },
};

export default ServiceServer;
