import React from "react";
import {
  Box,
  SxProps,
  Theme,
  IconButton,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
  Select,
  ListSubheader,
  Paper,
} from "@mui/material";
import { AppointmentDurations } from "../../../Server/Appointments/AppointmentTypes";
import { Styles } from "../../../../Types";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";
import EmployeeAvatar from "../../../Settings/Employees/EmployeeAvatar";
import Loading from "../../../Loading/Loading";
import { TimePicker } from "@mui/x-date-pickers";
import { EqualityCheck } from "@idot-digital/calendar-api";
import { Delete, Info } from "@mui/icons-material";
import NumberInput from "../../../Settings/Discounts/EditDiscount/NumberInput";
import { Service, Task } from "../../../Server/Services/ServiceTypes";

export interface AppointmentTimeSlotProps {
  allDurations: AppointmentDurations[];
  value: AppointmentDurations;
  conflicting: boolean;
  invalidTaskEmployeeCombination: boolean;
  canBeDeleted: boolean;
  onDelete: () => void;
  onChange: (duration: AppointmentDurations, error: boolean) => void;
  disabled?: boolean;
  services?: Service[];
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    p: 1,
  },
  row: {
    display: "flex",
    alignItems: "flex-end",
    gap: 1,
    height: "2.5rem",
  },
  timeInput: {
    flex: "1 0 4rem",
    width: "4rem",
    textAlign: "center",
    input: {
      textAlign: "center",
    },
  },
  timePickerWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  timeinputMargin: {
    my: 1,
  },
  deleteButton: {
    mt: 2,
    mr: -1,
  },
  extraTimeInput: {
    flex: "1 0 3rem",
    input: {
      textAlign: "center",
    },
  },
  avatar: {
    width: (theme) => theme.spacing(4),
    height: (theme) => theme.spacing(4),
    fontSize: (theme) => theme.spacing(2),
  },
  avatarButton: {
    // padding: (theme) => theme.spacing(0.5),
    padding: 0,
    marginRight: (theme) => theme.spacing(1),
  },
  listAvatar: {
    width: (theme) => theme.spacing(4),
    height: (theme) => theme.spacing(4),
    fontSize: (theme) => theme.spacing(2),
    mr: 1,
  },
} satisfies Styles;

export default function AppointmentTimeSlot(props: AppointmentTimeSlotProps) {
  const { data: employees, isSuccess: employeesLoaded } =
    EmployeeServer.useAll();
  const { data: employee } = EmployeeServer.use(props.value.employeeid);

  const ref = React.useRef<HTMLElement | null>(null);
  const [openEmployeeList, setOpenEmployeeList] = React.useState(false);

  const change = (duration: Partial<AppointmentDurations>) => {
    const newDuration = {
      ...props.value,
      ...duration,
    };

    // Check for errors
    // Duration must be at least 1 minute
    const error =
      newDuration.start.isEqual(newDuration.end) !== EqualityCheck.later;

    props.onChange(newDuration, error);
  };

  if (props.value.employeeid === -1 && props.value.taskid === null) return null;

  return (
    <Paper sx={styles.wrapper}>
      <Box sx={styles.row}>
        <IconButton
          ref={(ele) => (ref.current = ele)}
          onClick={() => setOpenEmployeeList(true)}
          sx={styles.avatarButton}
          disabled={props.disabled}
        >
          {
            <EmployeeAvatar
              sx={
                {
                  ...styles.avatar,
                  ...(props.disabled && {
                    filter: "grayscale(100%)",
                    opacity: 0.5,
                  }),
                } as SxProps<Theme>
              }
              employee={employee}
            />
          }
        </IconButton>
        <Popover
          anchorEl={ref.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openEmployeeList}
          onClose={() => setOpenEmployeeList(false)}
        >
          {employeesLoaded ? (
            <>
              {employees?.map((employee) => (
                <MenuItem
                  key={employee.id}
                  onClick={() => {
                    change({
                      employeeid: employee.id,
                    });
                    setOpenEmployeeList(false);
                  }}
                >
                  <EmployeeAvatar sx={styles.listAvatar} employee={employee} />
                  {employee.shortName}
                </MenuItem>
              ))}
            </>
          ) : (
            <Loading />
          )}
        </Popover>
        <Select
          variant="standard"
          sx={{ width: (theme) => `calc(100% - ${theme.spacing(9)})` }}
          error={props.invalidTaskEmployeeCombination}
          disabled={props.disabled || !props.services}
          value={props.value.taskid ?? -1}
          renderValue={(taskid) =>
            taskid === -1 ? (
              <Typography>
                <em>Keine Auswahl</em>
              </Typography>
            ) : (
              <Typography>
                {(() => {
                  for (const service of props.services ?? []) {
                    for (const task of service.tasks) {
                      if (task.id === taskid) {
                        return task.name;
                      }
                    }
                  }
                  return <em>Aufgabe nicht gefunden</em>;
                })()}
              </Typography>
            )
          }
          onChange={(e) => {
            if (typeof e.target.value !== "number") return;
            const taskid = e.target.value;
            const task = props.services
              ?.flatMap((service) => service.tasks)
              .find((task) => task.id === taskid);
            const index = props.allDurations.indexOf(props.value);
            if (!task || index === -1) {
              change({
                taskid: taskid === -1 ? null : taskid,
              });
              return;
            }
            const previousEndTime =
              index === 0
                ? props.value.start.copy()
                : props.allDurations[index - 1]?.end
                    .copy()
                    .add(props.allDurations[index - 1]?.follow_up_time);
            const startTime = previousEndTime
              .copy()
              .add(task.durations[0] + task.preparation_time);
            const endTime = previousEndTime
              .copy()
              .add(task.durations[1] + task.preparation_time);
            change({
              taskid,
              start: startTime,
              end: endTime,
              preparation_time: task.preparation_time,
              follow_up_time: task.follow_up_time,
            });
          }}
        >
          <MenuItem value={-1}>Keine Auswahl</MenuItem>
          {props.services?.flatMap((service) => [
            <ListSubheader key={"service-" + service.id}>
              {service.name}
            </ListSubheader>,
            ...Array.from(
              service.tasks
                .reduce((map, task) => {
                  const arr = map.get(task.variation_index) ?? [];
                  arr.push(task);
                  map.set(task.variation_index, arr);
                  return map;
                }, new Map<number, Task[]>())
                .entries()
            )
              .sort(([a], [b]) => a - b)
              .flatMap(([_, tasks], index, arr) => [
                ...(arr.length > 1
                  ? [
                      <ListSubheader
                        key={"service-" + service.id + "variant-" + index}
                        sx={{ lineHeight: 1.5 }}
                      >
                        Variante {index + 1}
                      </ListSubheader>,
                    ]
                  : []),
                ...tasks.map((task) => (
                  <MenuItem key={"task-" + task.id} value={task.id}>
                    {task.name}
                  </MenuItem>
                )),
              ]),
          ])}
        </Select>
        {props.invalidTaskEmployeeCombination && (
          <Tooltip
            placement="right"
            title={
              <Typography>
                Jede Aufgabe kann nur von jeweils einem Mitarbeiter ausgeführt
                werden
              </Typography>
            }
          >
            <Info color="error" />
          </Tooltip>
        )}
      </Box>
      <Box sx={styles.row}>
        <Tooltip title="Vorbereitung" sx={styles.extraTimeInput}>
          <NumberInput
            disabled={props.disabled}
            onChange={(time) =>
              change({
                preparation_time: time,
              })
            }
            value={props.value.preparation_time}
            sx={styles.extraTimeInput}
            InputProps={{
              endAdornment: "min",
            }}
          />
        </Tooltip>
        <TimePicker
          value={
            new Date(
              new Date().setHours(
                props.value.start.getHour(),
                props.value.start.getMinute(),
                0,
                0
              )
            )
          }
          onChange={(timedate) => {
            if (timedate && !isNaN(timedate?.getTime()))
              change({
                start: props.value.start
                  .copy()
                  .setHours(timedate.getHours(), timedate.getMinutes()),
              });
          }}
          slotProps={{
            textField: {
              placeholder: "hh:mm",
              sx: styles.timeInput,
              error:
                props.value.start.getHour() === -1 ||
                props.value.start.getHour() * 60 +
                  props.value.start.getMinute() >=
                  props.value.end.getHour() * 60 +
                    props.value.end.getMinute() ||
                props.conflicting,
              label: " ",
            },
          }}
          disableOpenPicker
          disabled={props.disabled}
        />
        <Typography
          sx={{
            ...styles.timeinputMargin,
            ...(props.disabled && {
              color: (theme) => theme.palette.text.disabled,
            }),
          }}
        >
          -
        </Typography>
        <TimePicker
          value={
            new Date(
              new Date().setHours(
                props.value.end.getHour(),
                props.value.end.getMinute()
              )
            )
          }
          onChange={(timedate) => {
            if (timedate && !isNaN(timedate?.getTime()))
              change({
                end: props.value.end
                  .copy()
                  .setHours(timedate.getHours(), timedate.getMinutes()),
              });
          }}
          slotProps={{
            textField: {
              placeholder: "hh:mm",
              sx: styles.timeInput,
              error:
                props.value.end.getHour() === -1 ||
                props.value.start.getHour() * 60 +
                  props.value.start.getMinute() >=
                  props.value.end.getHour() * 60 +
                    props.value.end.getMinute() ||
                props.conflicting,
              label: " ",
            },
          }}
          disableOpenPicker
          disabled={props.disabled}
        />
        <Tooltip title="Nachbereitung" sx={styles.extraTimeInput}>
          <NumberInput
            disabled={props.disabled}
            onChange={(time) =>
              change({
                follow_up_time: time,
              })
            }
            value={props.value.follow_up_time}
            sx={styles.extraTimeInput}
            slotProps={{
              input: {
                endAdornment: "min",
              },
            }}
          />
        </Tooltip>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          sx={styles.deleteButton}
          onClick={() => {
            props.onDelete();
          }}
          disabled={!props.canBeDeleted}
        >
          <Delete />
        </IconButton>
      </Box>
    </Paper>
  );
}
