import { Box, TextField, Typography } from "@mui/material";
import { Purchase } from "../../Server/Purchases/PurchasesTypes";
import { PartialPick } from "../../Server/Generic/GenericTypes";
import PriceInput from "../../Generic/PriceInput";
import CustomerSearch from "../../Calendar/Appointment/AppointmentPopup/CustomerSearch";
import CustomerServer from "../../Server/Customers/CustomerServer";
import { SearchCustomer } from "../../Server/Customers/CustomerTypes";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import PromiseButton from "../../Loading/PromiseButton";
import PurchasesServer from "../../Server/Purchases/PurchasesServer";
import { useServer } from "../../Server/ServerContext";
import PaymentMethodSelector from "../../Generic/PaymentMethodSelector";
import { PaymentMethod } from "../../Server/enums";

export default function EditPurchase() {
  const { account } = useServer();
  const [purchase, setPurchase] = React.useState<
    PartialPick<
      Purchase,
      "id" | "customer_id" | "created_by" | "payment_method"
    >
  >({
    created_at: new Date(),
    description: "",
    name: "",
    price: 0,
    created_by: account?.username!,
  });

  const { data: queryCustomer } = CustomerServer.use(purchase.customer_id);
  const [customer, setCustomer] = React.useState<SearchCustomer | null>(null);

  React.useEffect(() => {
    if (!customer && queryCustomer) setCustomer(queryCustomer);
  }, [queryCustomer]);

  React.useEffect(() => {
    if (customer?.id !== purchase.customer_id) setCustomer(null);
  }, [purchase.customer_id]);

  const hasError =
    !purchase.name ||
    !purchase.price ||
    !purchase.payment_method ||
    purchase.created_at.getTime() > Date.now();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h5">
        Verkauf {purchase.id ? "bearbeiten" : "erstellen"}
      </Typography>
      <CustomerSearch
        customer={customer ?? null}
        onChange={(customer) => {
          setCustomer(customer);
          setPurchase({ ...purchase, customer_id: customer?.id });
        }}
      />
      <TextField
        value={purchase.name}
        onChange={(e) => setPurchase({ ...purchase, name: e.target.value })}
        label="Name"
      />
      <TextField
        value={purchase.description}
        multiline
        maxRows={3}
        minRows={3}
        onChange={(e) =>
          setPurchase({ ...purchase, description: e.target.value })
        }
        label="Beschreibung"
      />
      <PriceInput
        value={purchase.price}
        onChange={(price) => setPurchase({ ...purchase, price: price ?? 0 })}
        label="Preis"
        variant="standard"
        sx={{ input: {} }}
      />
      <PaymentMethodSelector
        paymentMethod={purchase.payment_method || null}
        onChange={(payment_method) =>
          setPurchase({ ...purchase, payment_method })
        }
      />
      <DatePicker
        disableFuture
        label="Verkaufstag"
        value={purchase.created_at}
        onChange={(date) => {
          setPurchase({
            ...purchase,
            created_at: date ?? new Date(),
          });
        }}
      />
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <PromiseButton
          variant="contained"
          disabled={hasError}
          onClick={async () => {
            await PurchasesServer.create(purchase as Omit<Purchase, "id">);
            setPurchase({
              created_at: new Date(),
              description: "",
              name: "",
              price: 0,
              created_by: account?.username!,
            });
          }}
        >
          {purchase.id ? "Speichern" : "Erstellen"}
        </PromiseButton>
      </Box>
    </Box>
  );
}
