import React from "react";
import { CancelActivityLog } from "../../Server/ActivityLog/ActivityLogTypes";
import { useServer } from "../../Server/ServerContext";
import GenericLogDisplay from "./GenericLogDisplay";
import { SimpleDate } from "@idot-digital/calendar-api";
import { DiscountType } from "../../Server/Discounts/DiscountTypes";

export interface CustomerCancelLogDetailsProps {
  log: CancelActivityLog;
}

export default function CustomerCancelLogDetails(
  props: CustomerCancelLogDetailsProps
) {
  const { settings } = useServer();

  return (
    <GenericLogDisplay
      data={[
        {
          name: "Appointment ID",
          type: "string",
          data: props.log.appointmentid,
        },
        {
          name: "Services",
          type: "string",
          data: props.log.services,
        },
        {
          name: "Zusatzservices",
          type: "string",
          data: props.log.additional_services,
        },
        {
          name: "Mitarbeiter",
          type: "string",
          data: props.log.main_employee_name,
        },
        {
          name: "Kunde",
          type: "string",
          data: props.log.customer_name,
        },
        {
          name: "Preis",
          type: "money",
          data: props.log.price,
        },
        {
          name: "Rabatt",
          type: "string",
          data:
            props.log.discount_name === null
              ? "Kein Rabatt"
              : `${props.log.discount_name} (${
                  props.log.discount_amount! / 100
                }${
                  props.log.discount_type === DiscountType.percentage
                    ? "%"
                    : settings.getKey("currency_sign")
                })`,
        },
        {
          name: "Online gebucht",
          type: "boolean",
          data: props.log.online,
        },
        {
          name: "Datum",
          type: "string",
          data: SimpleDate.importInt(props.log.start).getDateTimeString(),
        },
      ]}
    />
  );
}
