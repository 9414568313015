import React from "react";
import { SimpleDate } from "@idot-digital/calendar-api";
import { ID } from "../../Types";
import AppointmentPopup from "./Appointment/AppointmentPopup/AppointmentPopup";
import { useServer } from "../Server/ServerContext";
import { Fab } from "@mui/material";
import { Add } from "@mui/icons-material";
import AppointmentWarning from "./Appointment/AppointmentPopup/AppointmentWarning";
import { useSnackbar } from "notistack";
import { useSaveSettings } from "../Settings/SaveSettingsProvider";
import {
  Appointment,
  EMPTY_APPOINTMENT,
} from "../Server/Appointments/AppointmentTypes";

export interface CalendarProps {
  openAppointment: (appointment: Appointment) => void;
  createAppointment: (employeeid: ID, date: Date) => void;
  switchView: (day?: SimpleDate) => void;
  gettingTimes: boolean;
  startDay?: SimpleDate;
}

export interface CalendarManagerContextProps {
  disableContent: boolean;
}

export interface CalendarManagerProps {
  calendar: (props: CalendarProps) => JSX.Element;
  switchView: () => void;
}

const NO_APPOINTMENT_SELECTED: {
  appointment?: Appointment;
  isNew: boolean;
  edit: boolean;
} = {
  appointment: undefined,
  isNew: true,
  edit: true,
};

const EMPTY_CALENDAR_MANAGER_CONTEXT: CalendarManagerContextProps = {
  disableContent: false,
};
const CalendarManagerContext = React.createContext<CalendarManagerContextProps>(
  EMPTY_CALENDAR_MANAGER_CONTEXT
);

export const useCalendarManager = () => {
  return (
    React.useContext(CalendarManagerContext) || EMPTY_CALENDAR_MANAGER_CONTEXT
  );
};

export default function CalendarManager(props: CalendarManagerProps) {
  const { account } = useServer();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { clear } = useSaveSettings();

  const [appointment, setAppointment] = React.useState(NO_APPOINTMENT_SELECTED);

  const [day, setDay] = React.useState<SimpleDate | undefined>(undefined);

  const [getTimes, setGetTimes] = React.useState(false);
  const getTimesPromise = React.useRef<
    ((duration: SimpleDate, employeeid: ID) => void) | undefined
  >(undefined);

  const [warning, setWarning] = React.useState<(() => void) | null>(null);
  const [open, setOpen] = React.useState(false);

  // Display snackbar while changing appointment duration
  React.useEffect(() => {
    if (getTimes)
      enqueueSnackbar(
        "Klicken Sie in den Kalender, um einen Zeitpunkt auszuwählen",
        {
          variant: "info",
          persist: true,
          key: "getTimes",
        }
      );
    else closeSnackbar("getTimes");
  }, [getTimes, enqueueSnackbar, closeSnackbar]);

  const Calendar = props.calendar;

  return (
    <CalendarManagerContext.Provider value={{ disableContent: getTimes }}>
      <Calendar
        startDay={day}
        gettingTimes={getTimes}
        openAppointment={(appointment) => {
          setAppointment({ appointment, isNew: false, edit: false });
          setOpen(true);
        }}
        switchView={(day) => {
          setDay(day);
          props.switchView();
        }}
        // gets used for creating new and getting new times for open appointment
        createAppointment={(employeeid, date) => {
          // only if new is created
          if (!open) {
            // create new appointment with data from where user clicked -> date and employee
            const newAppointment = EMPTY_APPOINTMENT(account, employeeid);
            const startDate = SimpleDate.fromDate(date);
            newAppointment.durations = [
              {
                start: startDate,
                end: startDate.copy().add(30),
                employeeid: newAppointment.main_employeeid,
                follow_up_time: 0,
                preparation_time: 0,
                taskid: null,
              },
            ];

            setAppointment({
              appointment: newAppointment,
              isNew: true,
              edit: true,
            });
            // Check if appointment is in the past
            if (date.getTime() < new Date().setHours(0, 0, 0, 0))
              setWarning(() => () => setOpen(true));
            else setOpen(true);
          } else if (getTimes)
            getTimesPromise.current?.(SimpleDate.fromDate(date), employeeid);
        }}
      />
      <Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(4),
          right: (theme) => theme.spacing(4),
          zIndex: 10,
        }}
        color="primary"
        onClick={() => {
          setAppointment({
            appointment: EMPTY_APPOINTMENT(account),
            isNew: true,
            edit: true,
          });
          setOpen(true);
        }}
      >
        <Add
          sx={{
            height: (theme) => theme.spacing(4),
            width: (theme) => theme.spacing(4),
          }}
        />
      </Fab>
      <AppointmentPopup
        appointment={appointment?.appointment}
        edit={appointment.edit}
        setEdit={(edit) => setAppointment({ ...appointment, edit })}
        isNew={appointment.isNew}
        onClose={() => {
          setOpen(false);
          setGetTimes(false);
          getTimesPromise.current = undefined;
        }}
        onClosed={() => clear()}
        open={open}
        getTimes={() => {
          setGetTimes(true);
          return new Promise<{ start: SimpleDate; employeeid: ID }>((res) => {
            getTimesPromise.current = (date: SimpleDate, employeeid: ID) => {
              if (date.toDate().getTime() < new Date().setHours(0, 0, 0, 0))
                setWarning(() => () => {
                  setGetTimes(false);
                  res({ start: date, employeeid });
                });
              else {
                setGetTimes(false);
                res({ start: date, employeeid });
              }
            };
          });
        }}
      />
      <AppointmentWarning
        open={Boolean(warning)}
        onClose={() => setWarning(null)}
        onConfirm={() => {
          warning?.();
          setWarning(null);
        }}
      />
    </CalendarManagerContext.Provider>
  );
}
