import React from "react";

import { SimpleDate } from "@idot-digital/calendar-api";

import AppointmentPopupContext from "./AppointmentPopupContext";
import AppointmentPopupManager from "./AppointmentPopupManager";
import { useServer } from "../../../Server/ServerContext";
import config from "../../../../config";
import {
  Appointment,
  AppointmentAttributes,
  AppointmentDurations,
  AppointmentTasks,
  EMPTY_APPOINTMENT,
  Price,
} from "../../../Server/Appointments/AppointmentTypes";
import { Employee } from "../../../Server/Employees/EmployeeTypes.d";
import { Service, Task } from "../../../Server/Services/ServiceTypes.d";
import { Customer } from "../../../Server/Customers/CustomerTypes.d";
import { ID } from "../../../../Types";
import AppointmentFunctions from "../../../Server/Appointments/AppointmentFunctions";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";
import ServiceServer from "../../../Server/Services/ServiceServer";
import CustomerServer from "../../../Server/Customers/CustomerServer";
import AccountServer from "../../../Server/Accounts/AccountServer";
import { ListAccount } from "../../../Server/Accounts/AccountTypes";
import AppointmentServer from "../../../Server/Appointments/AppointmentServer";
import { deepCopy } from "../../../../Functions/ObjectFunctions";
import { Arrays, Objects } from "@idot-digital/generic-helpers";

export interface AppointmentPopupProps {
  appointment?: Appointment;

  open?: boolean;
  edit?: boolean;
  isNew?: boolean;

  setEdit?: (edit: boolean) => void;
  onClose?: () => void;
  onClosed?: () => void;
  getTimes?: () => Promise<{ start: SimpleDate; employeeid: ID }>;

  // callback when saving appointment or saving customer
  onSave?: () => void;
}

export interface AppointmentPopupData extends AppointmentPopupProps {
  mounted: React.MutableRefObject<boolean>;
  update: any;

  appointment: Appointment;
  setAppointmentData: (
    data: Partial<Appointment>,
    callback?: (appointment: Appointment) => void
  ) => void;
  setAppointmentDurations: (
    durations: AppointmentDurations[],
    callback?: (appointment: Appointment) => void
  ) => void;

  resetDurations: () => void;
  default_durations: AppointmentDurations[] | null;
  durations: AppointmentDurations[];
  mainEmployee: Employee | null;
  contrastColor: string;
  services: Service[] | null;
  main_services: Service[];
  additional_services: Service[];
  price: number | null;
  totalPrice: number;
  defaultPrice: Price;
  createdBy: ListAccount | null;
  createdAt: Date | null;
  online: boolean;
  attributes: AppointmentAttributes | null;
  customer: Customer | null;
  appointmentValid: boolean;
  manual_mode: boolean;

  loaded: boolean;
  appointmentError: string | null;
  timeError: boolean;
}

export default function AppointmentPopup(props: AppointmentPopupProps) {
  props = {
    open: false,
    edit: false,
    isNew: false,
    ...props,
  };
  const { account } = useServer();

  // Check mounted state
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const parseAppointment = React.useCallback(
    () =>
      AppointmentFunctions.copy(
        props.appointment || EMPTY_APPOINTMENT(account)
      ),
    [props.appointment, account]
  );

  const [update, queueUpdate] = React.useReducer((x) => !x, false);
  const [appointment, setAppointment] = React.useState(parseAppointment());

  React.useEffect(() => {
    if (props.open) {
      setAppointment(parseAppointment());
      // Queue update in order to prevent lifecycle errors when opening
      // children could use invalid data from former appointments
      queueUpdate();
    }
    // Only update on new appointment prop and when opening
    // eslint-disable-next-line
  }, [props.appointment, props.open]);

  const internalSetAppointmentData = React.useCallback(
    (
      data: Partial<Appointment>,
      callback?: (appointment: Appointment) => void
    ) => {
      setAppointment((appointment) => {
        const newAppointment = {
          ...appointment,
          ...data,
        };

        callback?.(newAppointment);

        return newAppointment;
      });
    },
    []
  );

  const internalSetAppointmentDurations = React.useCallback(
    (
      durations: AppointmentDurations[],
      callback?: (appointment: Appointment) => void
    ) => {
      setAppointment((appointment) => {
        const newAppointment = {
          ...appointment,
          durations,
        };

        callback?.(newAppointment);

        return newAppointment;
      });
    },
    []
  );

  const durations = React.useMemo(
    () => appointment.durations,
    [appointment.durations]
  );

  const timeError = React.useMemo(
    () => AppointmentFunctions.hasConflictingDurations(durations),
    [durations]
  );

  const { data: mainEmployee = null, isSuccess: mainEmployeeLoaded } =
    EmployeeServer.use(appointment.main_employeeid);

  const contrastColor = React.useMemo(
    () =>
      config.employeeColors.find((color) => color.color === mainEmployee?.color)
        ?.contrastText || "#fff",
    [mainEmployee]
  );

  const { data: allServices } = ServiceServer.useAll();

  const servicesRes = ServiceServer.useMultiple(
    Array.from(
      new Set(Arrays.filterNull(appointment.tasks.map((t) => t.serviceid)))
    )
  );

  const services = React.useMemo(
    () =>
      servicesRes
        .map((res) => res.data)
        .filter((service) => service) as Service[],
    [servicesRes]
  );
  const servicesLoaded = servicesRes.every((res) => res.isSuccess);

  const main_services = React.useMemo(
    () => services.filter((service) => !service.is_additional_service),
    [services]
  );
  const additional_services = React.useMemo(
    () => services.filter((service) => service.is_additional_service),
    [services]
  );

  const price = React.useMemo(
    () => appointment.price || null,
    [appointment.price]
  );

  const totalPrice = React.useMemo(
    () =>
      (price || 0) +
      appointment.tasks.reduce(
        (total, task) => total + task.price_difference,
        0
      ),
    [appointment.tasks, price]
  );

  const {
    data: rawDefaultPrice = {
      default_price: 0,
      discountid: null,
      discounted_price: 0,
      task_prices: [],
    },
    isSuccess: defaultPriceLoaded,
  } = AppointmentServer.usePrice(
    durations[0]?.start ?? SimpleDate.now(),
    appointment.tasks
      .filter((t) => t.taskid)
      .map((t) => ({
        taskid: t.taskid!,
        employeeid:
          appointment.durations.find((d) => d.taskid === t.taskid)
            ?.employeeid || appointment.main_employeeid,
      }))
  );

  // add legacy prices of untracked tasks to total price
  const defaultPrice = React.useMemo(() => {
    const untrackedPrice = appointment.tasks.reduce(
      (acc, task) => (task.taskid ? acc : acc + task.task_price),
      0
    );
    return {
      default_price: rawDefaultPrice.default_price + untrackedPrice,
      discountid: rawDefaultPrice.discountid,
      discounted_price: rawDefaultPrice.discounted_price + untrackedPrice,
      task_prices: rawDefaultPrice.task_prices,
    };
  }, [rawDefaultPrice, appointment.tasks]);

  // Autofill current price if price did not change
  React.useEffect(() => {
    if (!defaultPriceLoaded) return;
    // preserve discount while new and had discount
    if (props.isNew && appointment.discountid) {
      internalSetAppointmentData({
        price: defaultPrice.discounted_price,
        discountid: defaultPrice.discountid,
      });
      // update price if is new or recalculation scheduled by setting price to null
    } else if (
      (props.isNew && price !== defaultPrice.default_price) ||
      price === null
    ) {
      internalSetAppointmentData({
        price: defaultPrice.default_price,
        discountid: null,
        tasks: appointment.tasks.map((task) => ({
          ...task,
          task_price:
            defaultPrice.task_prices.find((t) => t.taskid === task.taskid)
              ?.price ?? task.task_price,
        })),
      });
    }
  }, [
    defaultPrice,
    price,
    props.isNew,
    defaultPriceLoaded,
    internalSetAppointmentData,
  ]);

  const { data: createdBy = null, isSuccess: createdByLoaded } =
    AccountServer.use(appointment.created_by);

  const createdAt = React.useMemo(
    () => appointment.created_at || null,
    [appointment.created_at]
  );

  const online = React.useMemo(() => appointment.online, [appointment.online]);

  const attributes = React.useMemo(
    () => appointment.attributes,
    [appointment.attributes]
  );

  const { data: customer = null, isSuccess: customerLoaded } =
    CustomerServer.use(appointment.customerid);

  const manual_mode = React.useMemo(
    () => appointment.manual_mode,
    [appointment.manual_mode]
  );

  // null means there are no default durations -> server could not find any matching combinations
  const { data: default_durations = null, isSuccess: defaultDurationsLoaded } =
    AppointmentServer.useDurations(
      Arrays.filterNull(appointment.tasks.map((t) => t.serviceid)),
      appointment.durations[0].start,
      appointment.main_employeeid,
      props.isNew ? undefined : appointment.id
    );

  // set durations when durations are loaded (also when manual mode is disabled)
  React.useEffect(() => {
    if (!default_durations || !props.edit || manual_mode) return;

    // get tasks from durations - and keep price differences in process
    const tasks = Array.from(new Set(default_durations.map((d) => d.taskid)))
      .filter(Boolean)
      .map((taskid) => {
        const existing = appointment?.tasks.find((t) => t.taskid === taskid);
        const task = allServices?.reduce<Task | undefined>(
          (task, s) => task ?? s.tasks.find((t) => t.id === taskid),
          undefined
        );
        const employeeid =
          default_durations.find((d) => d.taskid === taskid)?.employeeid ??
          appointment.main_employeeid;
        return {
          employeeid,
          price_difference: existing?.price_difference ?? 0,
          factor: existing?.factor ?? task?.factor ?? 1,
          serviceid: existing?.serviceid ?? task?.serviceid ?? null,
          task_price:
            task?.allowed_employees.find((e) => e.employeeid === employeeid)
              ?.price ?? 0,
          taskid,
        };
      });

    if (props.isNew) {
      internalSetAppointmentData({
        tasks,
      });
    } else {
      // gets price changes by checking which tasks are added and removed or which tasks have different employees
      const priceToAdd = (() => {
        const newTaskIDs = default_durations
          .map((d) => ({ taskid: d.taskid, employeeid: d.employeeid }))
          .filter(
            (task, index, self) =>
              index === self.findIndex((t) => t.taskid === task.taskid)
          );
        const addedTasks = newTaskIDs.filter(
          (task) =>
            !appointment.durations.some(
              (d) =>
                d.taskid === task.taskid && d.employeeid === task.employeeid
            )
        );
        return addedTasks.reduce((acc, { taskid, employeeid }) => {
          const task = allServices?.reduce<Task | undefined>(
            (res, s) => res || s.tasks.find((t) => t.id === taskid),
            undefined
          );
          if (!task) return acc;
          const employee = task.allowed_employees.find(
            (e) => e.employeeid === employeeid
          );
          if (!employee) return acc;
          return acc + employee.price;
        }, 0);
      })();
      const priceToSubtract = (() => {
        const existingTasks = appointment.durations
          .map((d) => ({ taskid: d.taskid, employeeid: d.employeeid }))
          .filter(
            (task, index, self) =>
              index === self.findIndex((t) => t.taskid === task.taskid)
          );
        const removedTasks = existingTasks.filter(
          (d) =>
            !default_durations.some(
              (dd) => dd.taskid === d.taskid && dd.employeeid === d.employeeid
            )
        );
        return removedTasks.reduce((acc, { taskid, employeeid }) => {
          const task = allServices?.reduce<Task | undefined>(
            (res, s) => res || s.tasks.find((t) => t.id === taskid),
            undefined
          );
          if (!task) return acc;
          const employee = task.allowed_employees.find(
            (e) => e.employeeid === employeeid
          );
          if (!employee) return acc;
          return acc + employee.price;
        }, 0);
      })();
      const price = Math.max(
        appointment.price + priceToAdd - priceToSubtract,
        0
      );

      // plase do not ask why the timeout is needed - when transitioning from manual mode to automatic mode the tasks are missing otherwise
      internalSetAppointmentData({
        tasks,
        price,
      });
    }

    internalSetAppointmentDurations(deepCopy(default_durations));
  }, [
    default_durations,
    manual_mode,
    allServices,
    internalSetAppointmentDurations,
    internalSetAppointmentData,
  ]);

  // durations and tasks actions for manual_mode
  React.useEffect(() => {
    if (!manual_mode) return;

    const durations = appointment.durations.filter((d) => d.employeeid !== -1);

    const tasks = Array.from(new Set(durations.map((d) => d.taskid)))
      .filter(Boolean)
      .map<AppointmentTasks>((taskid) => {
        const existing = appointment?.tasks.find((t) => t.taskid === taskid);
        const task = allServices?.reduce<Task | undefined>(
          (task, s) => task ?? s.tasks.find((t) => t.id === taskid),
          undefined
        );
        const employeeid =
          durations.find((d) => d.taskid === taskid)?.employeeid ??
          appointment.main_employeeid;
        return {
          employeeid,
          price_difference: existing?.price_difference ?? 0,
          factor: existing?.factor ?? task?.factor ?? 1,
          serviceid: existing?.serviceid ?? task?.serviceid ?? null,
          task_price:
            task?.allowed_employees.find((e) => e.employeeid === employeeid)
              ?.price ?? 0,
          taskid,
        };
      })
      .concat(
        appointment.tasks.filter(
          (t) => !durations.some((d) => d.taskid === t.taskid)
        )
      )
      .filter(
        (t1, i1, tasks) =>
          (t1.taskid !== -1 && t1.employeeid !== null) ||
          !tasks.some((t2, i2) => t1.serviceid === t2.serviceid && i1 > i2)
      );

    if (
      Objects.deepEqual(tasks, appointment.tasks) &&
      Objects.deepEqual(durations, appointment.durations)
    )
      return;

    internalSetAppointmentData({ tasks });
    internalSetAppointmentDurations(durations);
  }, [
    manual_mode,
    allServices,
    appointment.tasks,
    appointment.durations,
    internalSetAppointmentData,
    internalSetAppointmentDurations,
  ]);

  const resetDurations = React.useCallback(() => {
    internalSetAppointmentDurations([
      {
        start: durations[0]?.start ?? SimpleDate.now(),
        end: durations[0]?.end ?? SimpleDate.now(),
        employeeid: -1,
        follow_up_time: 0,
        preparation_time: 0,
        taskid: null,
      },
      ...durations,
    ]);
  }, [internalSetAppointmentDurations, durations]);

  const appointmentValid = React.useMemo(
    // if manual mode is not enabled and there are no default durations the appointment is invalid since there are no possible durations for the service
    () =>
      AppointmentFunctions.isValid(appointment) &&
      (manual_mode || default_durations !== null),
    [appointment, default_durations, manual_mode]
  );

  const loaded = React.useMemo(() => {
    if (!servicesLoaded && appointment.tasks.length) return false;
    if (!mainEmployeeLoaded && appointment.main_employeeid > 0) return false;
    if (!defaultDurationsLoaded) return false;
    if (
      !customerLoaded &&
      appointment.customerid !== null &&
      appointment.customerid > 0
    )
      return false;
    if (!createdByLoaded && appointment.created_by !== null) return false;
    return true;
  }, [
    servicesLoaded,
    customerLoaded,
    mainEmployeeLoaded,
    createdByLoaded,
    defaultDurationsLoaded,
    appointment.created_by,
    appointment.customerid,
    appointment.main_employeeid,
    appointment.tasks,
  ]);

  // check data when manual mode gets disabled
  React.useEffect(() => {
    if (manual_mode) return;
    // filter out services that the main employee has no time for or is not allowed to to (not allowed for any of the tasks)
    const filtered_services = services.filter((service) => {
      if (
        !service.tasks.some((t) =>
          t.allowed_employees.some(
            (e) => e.price && e.employeeid === appointment.main_employeeid
          )
        )
      )
        return false;
      if (
        !service.tasks.some((task) =>
          task.allowed_employees.some(
            (e) => e.employeeid === appointment.main_employeeid
          )
        )
      )
        return false;
      return true;
    });
    const tasks = appointment.tasks.filter(
      ({ taskid }) =>
        !filtered_services.some(({ tasks }) =>
          tasks.some(({ id }) => id === taskid)
        )
    );
    internalSetAppointmentData({
      tasks,
    });
    // only update when manual_mode changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manual_mode]);

  /*          Exposed setters          */
  // Firing additional events in order to update the appointment

  const setAppointmentData = React.useCallback(
    (
      data: Partial<Appointment>,
      callback?: (appointment: Appointment) => void
    ) => {
      // Fire additional events
      // autofillPrice(data);

      internalSetAppointmentData(data, callback);
    },
    [internalSetAppointmentData]
  );

  const setAppointmentDurations = React.useCallback(
    (
      durations: AppointmentDurations[],
      callback?: (appointment: Appointment) => void
    ) => {
      // Fire additional events
      if (manual_mode) {
        // update internal tasks data for manual mode
        const tasks = appointment.tasks.map((t) => {
          const task = services
            .find((s) => s.id === t.serviceid)
            ?.tasks.find((t1) => t1.id === t.taskid);
          const duration = durations.find((d) => d.taskid === t.taskid);
          return {
            ...t,
            task_price:
              task && duration
                ? (task.allowed_employees.find(
                    (e) => e.employeeid === duration.employeeid
                  )?.price ?? 0)
                : t.task_price,
            employeeid: duration?.employeeid ?? null,
          };
        });
        internalSetAppointmentData({ tasks });
      }

      internalSetAppointmentDurations(durations, callback);
    },
    [
      internalSetAppointmentDurations,
      manual_mode,
      appointment.tasks,
      services,
      durations,
      internalSetAppointmentData,
    ]
  );

  /*          Helper functions          */

  const appointmentError = React.useMemo(() => null as null | string, []);

  return (
    <AppointmentPopupContext
      {...{
        ...props,

        mounted,
        update,

        appointment,
        setAppointmentData,
        setAppointmentDurations,

        resetDurations,
        default_durations,
        durations,
        mainEmployee,
        contrastColor,
        services,
        main_services,
        additional_services,
        price,
        totalPrice,
        defaultPrice,
        createdBy,
        createdAt,
        online,
        attributes,
        customer,
        appointmentValid,
        manual_mode,

        loaded,
        appointmentError,
        timeError,
      }}
    >
      <AppointmentPopupManager />
    </AppointmentPopupContext>
  );
}
