import React from "react";
import SettingsManager from "./Settings/SettingsManager";
import { Account, Permission } from "./Accounts/AccountTypes";
import Loading from "../Loading/Loading";

const ServerContext = React.createContext<ServerHook>(undefined!);

export function useServer() {
  return React.useContext(ServerContext);
}

export function ServerProvider(props: {
  children: JSX.Element;
  account: Account | null;
  setAccount: (account: Account | null) => void;
}) {
  const [loaded, setloaded] = React.useState(false);
  const [loggedInAccount, setLoggedInAccount] = React.useState<Account | null>(
    null
  );

  React.useEffect(() => {
    if (!props.account?.permissions.includes(Permission.store_account))
      setLoggedInAccount(props.account);
  }, [props.account]);

  const [settings] = React.useState<SettingsManager>(new SettingsManager());
  //@ts-ignore
  window.settings = settings;

  async function loadAndSetData() {
    try {
      await settings.load();
      // TODO: prefetch some data to have one intial load and less loading screens

      if (!loaded) setloaded(true);
    } catch (error) {
      console.error(error);
    }
  }

  // initially load settings
  React.useEffect(() => {
    loadAndSetData();
    //eslint-disable-next-line
  }, []);

  const employeeLogin =
    !loaded || props.account?.permissions.includes(Permission.store_account);

  const exportValue = {
    settings,
    storeAccount: employeeLogin ? props.account : null,
    setStoreAccount: props.setAccount,
    account: loggedInAccount,
    setAccount: employeeLogin ? setLoggedInAccount : props.setAccount,
  };

  //@ts-ignore
  window.server = exportValue;

  if (!loaded) return <Loading />;

  return (
    <ServerContext.Provider value={exportValue}>
      {props.children}
    </ServerContext.Provider>
  );
}

export interface ServerHook {
  settings: SettingsManager;
  storeAccount: Account | null;
  setStoreAccount: (account: Account | null) => void;
  account: Account | null;
  setAccount: (account: Account | null) => void;
}
