import {
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  Popover,
  Skeleton,
  Typography,
} from "@mui/material";
import { TaskAllowedEmployee } from "../../../Server/Services/ServiceTypes";
import EmployeeServer from "../../../Server/Employees/EmployeeServer";
import EmployeeAvatar from "../../Employees/EmployeeAvatar";
import { ListEmployee } from "../../../Server/Employees/EmployeeTypes";
import React from "react";
import { ID } from "../../../../Types";
import PriceInput from "../../../Generic/PriceInput";

export interface EmployeePriceSelectorProps {
  allowed_employees: TaskAllowedEmployee[];
  onChange: (allowed_employees: TaskAllowedEmployee[]) => void;
}

export default function EmployeePriceSelector(
  props: EmployeePriceSelectorProps
) {
  const { data: employees, isSuccess: employeesLoaded } =
    EmployeeServer.useAll();

  // Map<employeeid, price>
  const lastPrices = React.useRef(new Map<ID, number>());

  const anchorEl = React.useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const displayError = props.allowed_employees.some((e) => !e.price) && !open;

  if (!employeesLoaded || !employees)
    return <Skeleton variant="rounded" width={150} height={32} />;

  return (
    <>
      <Button
        variant={
          !props.allowed_employees.length || displayError
            ? "contained"
            : "outlined"
        }
        color={
          displayError
            ? "error"
            : props.allowed_employees.length
              ? "primary"
              : "inherit"
        }
        ref={anchorEl}
        onClick={() => setOpen(true)}
        startIcon={
          <AvatarGroup
            max={4}
            sx={{ justifyContent: "flex-end", mr: 1 }}
            slotProps={{
              surplus: {
                sx: {
                  height: "1.5em",
                  width: "1.5em",
                  fontSize: "0.75em",
                },
              },
            }}
          >
            {(
              props.allowed_employees
                .map((allowed) =>
                  employees.find((e) => e.id === allowed.employeeid)
                )
                .filter(Boolean) as ListEmployee[]
            ).map((e) => (
              <EmployeeAvatar
                employee={e}
                key={e.id}
                sx={{
                  height: "1.5em",
                  width: "1.5em",
                  fontSize: "0.75em",
                }}
              />
            ))}
          </AvatarGroup>
        }
      >
        Mitarbeiter
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1} display="flex" flexDirection="column" gap={1}>
          {employees?.map((employee) => (
            <Box
              key={employee.id}
              display="grid"
              gridTemplateColumns="auto auto 1fr auto"
              alignItems="center"
            >
              <Checkbox
                checked={props.allowed_employees.some(
                  (e) => e.employeeid === employee.id
                )}
                onChange={(_, checked) => {
                  if (checked) {
                    props.onChange([
                      ...props.allowed_employees,
                      {
                        employeeid: employee.id,
                        price: lastPrices.current.get(employee.id) || 0,
                      },
                    ]);
                  } else {
                    lastPrices.current.set(
                      employee.id,
                      props.allowed_employees.find(
                        (e) => e.employeeid === employee.id
                      )?.price || 0
                    );
                    props.onChange(
                      props.allowed_employees.filter(
                        (e) => e.employeeid !== employee.id
                      )
                    );
                  }
                }}
              />
              <EmployeeAvatar employee={employee} />
              <Typography mx={1}>{employee.shortName}</Typography>
              <PriceInput
                variant="standard"
                sx={{ width: 75 }}
                value={
                  props.allowed_employees.find(
                    (e) => e.employeeid === employee.id
                  )?.price ||
                  lastPrices.current.get(employee.id) ||
                  0
                }
                onChange={(price) => {
                  props.onChange(
                    props.allowed_employees.map((e) =>
                      e.employeeid === employee.id
                        ? { ...e, price: price ?? 0 }
                        : e
                    )
                  );
                }}
                disabled={
                  !props.allowed_employees.some(
                    (e) => e.employeeid === employee.id
                  )
                }
              />
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
}
