import React from "react";
import { Box } from "@mui/material";
import {
  ActivityLogType,
  ActualWorkhoursActivityLog,
  AppointmentActivityLog,
  AppointmentPaymentActivityLog,
  CancelActivityLog,
  CategoryActivityLog,
  CouponActivityLog,
  DiscountActivityLog,
  ListActivityLog,
  PriceActivityLog,
  PurchasesActivityLog,
  RefundActivityLog,
  ServiceActivityLog,
} from "../../Server/ActivityLog/ActivityLogTypes";
import AppointmentsLogDetails from "./AppointmentsLogDetails";
import CustomerCancelLogDetails from "./CustomerCancelLogDetails";
import CategoryLogDetails from "./CategoryLogDetails";
import ServiceLogDetails from "./ServiceLogDetails";
import DiscountLogDetails from "./DiscountLogDetails";
import WorkhourLogDetails from "./WorkhourLogDetails";
import PurchasesLogDetails from "./PurchasesLogDetails";
import RefundLogDetails from "./RefundLogDetails";
import AppointmentPaymentLogDetails from "./AppointmentPaymentLogDetails";
import CouponLogDetails from "./CouponLogDetails";

export interface LogDetailsProps<T extends ActivityLogType> {
  activity: ListActivityLog<T> | null;
  type: T | null;
}

export default function LogDetails<T extends ActivityLogType>(
  props: LogDetailsProps<T>
) {
  if (!props.activity || !props.type || props.activity.type !== props.type)
    return null;

  switch (props.activity.type) {
    case ActivityLogType.bookings:
      return (
        <AppointmentsLogDetails
          log={props.activity as AppointmentActivityLog}
        />
      );
    case ActivityLogType.cancel:
      return (
        <CustomerCancelLogDetails log={props.activity as CancelActivityLog} />
      );
    case ActivityLogType.categories:
      return <CategoryLogDetails log={props.activity as CategoryActivityLog} />;
    case ActivityLogType.services:
      return <ServiceLogDetails log={props.activity as ServiceActivityLog} />;
    case ActivityLogType.discount:
      return <DiscountLogDetails log={props.activity as DiscountActivityLog} />;
    case ActivityLogType.actual_workhours:
      return (
        <WorkhourLogDetails
          log={props.activity as ActualWorkhoursActivityLog}
        />
      );
    case ActivityLogType.purchases:
      return (
        <PurchasesLogDetails log={props.activity as PurchasesActivityLog} />
      );
    case ActivityLogType.coupons:
      return <CouponLogDetails log={props.activity as CouponActivityLog} />;
    case ActivityLogType.refund:
      return <RefundLogDetails log={props.activity as RefundActivityLog} />;
    case ActivityLogType.appointment_payment:
      return (
        <AppointmentPaymentLogDetails
          log={props.activity as AppointmentPaymentActivityLog}
        />
      );
    default:
      return <Box>not implemented</Box>;
  }
}
