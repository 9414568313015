import React from "react";
import { AppointmentDurations } from "../../../Server/Appointments/AppointmentTypes";
import AppointmentTimeSlot from "./AppointmentTimeSlot";
import AppointmentFunctions from "../../../Server/Appointments/AppointmentFunctions";
import {
  Box,
  Button,
  Divider,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { StaticDatePicker, TimePicker } from "@mui/x-date-pickers";
import { useAppointmentPopup } from "./AppointmentPopupContext";
import { Styles } from "../../../../Types";
import { Service } from "../../../Server/Services/ServiceTypes";

export interface AppointmentPopupDurationsProps {
  value: AppointmentDurations[];
  onChange: (durations: AppointmentDurations[], error: boolean) => void;
  services?: Service[];
}

const styles: Styles = {
  timeInput: {
    flex: "1 0 4.75rem",
    width: "4.75rem",
    textAlign: "center",
    input: {
      textAlign: "center",
    },
  },
};

export default function AppointmentPopupDurations(
  props: AppointmentPopupDurationsProps
) {
  const { manual_mode, default_durations, services, mainEmployee } =
    useAppointmentPopup();

  const conflicting = React.useMemo(
    () => AppointmentFunctions.getConflictingDurations(props.value),
    [props.value]
  );

  const duplicateTaskEmployeeCombination = React.useMemo(
    () =>
      props.value
        .map((dur) => ({
          taskid: dur.taskid,
          employeeid: dur.employeeid!,
        }))
        .filter((t) => t.taskid)
        .filter((task, _, arr) =>
          arr.some(
            (t) => t.taskid === task.taskid && t.employeeid !== task.employeeid
          )
        ),
    [props.value]
  );

  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const anchorEl = React.useRef<HTMLElement | null>();

  return (
    <Box width="100%">
      <Box display="flex" gap={1} alignItems="flex-end">
        <TextField
          value={props.value[0].start.getDateString(true, true, true)}
          slotProps={{
            input: {
              readOnly: true,
            },
          }}
          label="Tag"
          fullWidth
          onClick={() => setOpenDatePicker(true)}
          ref={(ref) => (anchorEl.current = ref)}
          focused={false}
        />
        <Popover
          open={openDatePicker}
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setOpenDatePicker(false)}
        >
          <StaticDatePicker
            value={props.value[0].start.toDate()}
            onChange={(date) => {
              if (date && !isNaN(date.getTime()))
                props.onChange(
                  props.value.map((dur) => {
                    dur.start = dur.start
                      .copy()
                      .setYear(
                        date.getFullYear(),
                        date.getMonth() + 1,
                        date.getDate()
                      );
                    dur.end = dur.end
                      .copy()
                      .setYear(
                        date.getFullYear(),
                        date.getMonth() + 1,
                        date.getDate()
                      );
                    return { ...dur };
                  }),
                  false
                );
            }}
            displayStaticWrapperAs="desktop"
            views={["day"]}
            openTo="day"
            // don't show days outside current month since they don't give callbacks
          />
        </Popover>
        {!manual_mode && (
          <TimePicker
            value={
              props.value.length
                ? new Date(
                    new Date().setHours(
                      props.value[0]?.start.getHour(),
                      props.value[0]?.start.getMinute(),
                      0,
                      0
                    )
                  )
                : new Date()
            }
            onChange={(timedate) => {
              if (timedate && !isNaN(timedate?.getTime()))
                props.onChange(
                  // set start of first duration to new time -> default_durations get updated
                  props.value.map((dur, index) => {
                    if (index === 0)
                      return {
                        ...dur,
                        start: dur.start
                          .copy()
                          .setHours(timedate.getHours(), timedate.getMinutes()),
                      };
                    return dur;
                  }),
                  false
                );
              else return props.onChange(props.value, true);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                placeholder: "hh:mm",
                sx: styles.timeInput,
                error: conflicting.length > 0,
                label: "Startzeit",
              },
            }}
          />
        )}
      </Box>
      {manual_mode || default_durations !== null ? (
        props.value.map((duration, index) => (
          <Box mt={2} key={index}>
            <AppointmentTimeSlot
              disabled={!manual_mode}
              key={index}
              canBeDeleted={props.value.length > 1 && manual_mode}
              onChange={(duration, error) => {
                const newDurations = [...props.value];
                newDurations[index] = duration;
                props.onChange(newDurations, error);
              }}
              allDurations={props.value}
              value={duration}
              services={props.services}
              onDelete={() => {
                const newDurations = [...props.value];
                newDurations.splice(index, 1);
                props.onChange(newDurations, props.value.length > 0);
              }}
              conflicting={conflicting.includes(index)}
              invalidTaskEmployeeCombination={duplicateTaskEmployeeCombination.some(
                (d) =>
                  d.taskid === duration.taskid &&
                  d.employeeid === duration.employeeid
              )}
            />
          </Box>
        ))
      ) : (
        <Typography textAlign="center" margin={1} color="error">
          {services?.length
            ? mainEmployee &&
              services.every((s) =>
                s.tasks.every(
                  (t) =>
                    !t.allowed_employees.some(
                      (t) => t.employeeid === mainEmployee.id
                    )
                )
              )
              ? "Die ausgewählte Hauptmitarbeiterin bietet den ausgewählten Service nicht an"
              : "Keine Zeiten gefunden"
            : "Kein Service ausgewählt"}
        </Typography>
      )}
      {manual_mode && (
        <Button
          startIcon={<Add />}
          onClick={() => {
            const currentEnd = props.value[props.value.length - 1].end.copy();
            props.onChange(
              [
                ...props.value,
                {
                  preparation_time: 0,
                  employeeid: props.value[0].employeeid,
                  follow_up_time: 0,
                  start: currentEnd.copy(),
                  end: currentEnd.copy().add(30),
                  taskid: null,
                },
              ],
              false
            );
          }}
        >
          Zeit hinzufügen
        </Button>
      )}
    </Box>
  );
}
