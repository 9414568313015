import React from "react";
import { Styles } from "../../../Types";
import {
  Box,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import TextFieldWrapper from "../TextFieldWrapper";
import { AppointmentAttributes } from "../../Server/Appointments/AppointmentTypes";

export interface CustomerAttributesProps {
  attributes: AppointmentAttributes | null;
  onChange: (attributes: AppointmentAttributes) => void;
}

export interface DisplayCustomerAttributesProps
  extends Omit<CustomerAttributesProps, "onChange"> {}

const styles: Styles = {
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  tabButtons: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
  },
  title: {
    textAlign: "center",
    width: "100%",
  },
  header: {
    position: "relative",
  },
  toggleButtonOverwrite: {
    color: "inherit",
    padding: (theme) => theme.spacing(0, 1),
  },
  tabButtonsOverwrite: {
    marginLeft: (theme) => theme.spacing(2),

    "& > .MuiToggleButton-root": {
      opacity: 0.7,
    },

    "& > .Mui-selected": {
      color: "inherit",
      opacity: 1,
    },
  },
};

const FIELD_TITLES: { [key in keyof AppointmentAttributes]: string } = {
  coloration: "Coloration",
  perm: "Dauerwelle",
};

const FIELD_NAMES: AppointmentAttributes = {
  coloration: [
    {
      color: "Farbe",
      nuance: "Nuance",
      strands: "Strähnen",
      amount: "Menge",
      hydrogen: "Wasserstoff",
      exposure_time: "Einwirkzeit",
      preparation_time: "Vorbehandlung",
      follow_up_time: "Nachbehandlung",
      notes: "Bemerkung",
    },
    {
      color: "Farbe",
      nuance: "Nuance",
      strands: "Strähnen",
      amount: "Menge",
      hydrogen: "Wasserstoff",
      exposure_time: "Einwirkzeit",
      preparation_time: "Vorbehandlung",
      follow_up_time: "Nachbehandlung",
      notes: "Bemerkung",
    },
    {
      color: "Farbe",
      nuance: "Nuance",
      strands: "Strähnen",
      amount: "Menge",
      hydrogen: "Wasserstoff",
      exposure_time: "Einwirkzeit",
      preparation_time: "Vorbehandlung",
      follow_up_time: "Nachbehandlung",
      notes: "Bemerkung",
    },
  ],
  perm: [
    {
      winding_strength: "Wickelstärke",
      product: "Produkt",
      exposure_time: "Einwirkzeit",
      temperature: "Wärme",
      preparation_time: "Vorbehandlung",
      notes: "Bemerkung",
    },
    {
      winding_strength: "Wickelstärke",
      product: "Produkt",
      exposure_time: "Einwirkzeit",
      temperature: "Wärme",
      preparation_time: "Vorbehandlung",
      notes: "Bemerkung",
    },
    {
      winding_strength: "Wickelstärke",
      product: "Produkt",
      exposure_time: "Einwirkzeit",
      temperature: "Wärme",
      preparation_time: "Vorbehandlung",
      notes: "Bemerkung",
    },
  ],
};

const MAPPED_FIELD_NAMES: [
  keyof AppointmentAttributes,
  [
    keyof AppointmentAttributes[keyof AppointmentAttributes][number],
    string,
  ][][],
][] = (Object.entries(FIELD_NAMES) as [keyof AppointmentAttributes, any][]).map(
  ([key, value]) => [
    key,
    value.map((v: { [key: string]: string }) => Object.entries(v)),
  ]
);

const FIELD_TABS = Object.entries(FIELD_NAMES).map(([key, value]) => [
  key,
  value.length,
]) as [keyof AppointmentAttributes, number][];

export default function CustomerAttributes(props: CustomerAttributesProps) {
  const [tab, setTab] = React.useState(
    // Set current tabs to the first tab
    Object.fromEntries(FIELD_TABS.map(([key]) => [key, 0])) as {
      [key in keyof AppointmentAttributes]: number;
    }
  );

  if (!props.attributes)
    return (
      <Box sx={styles.center}>
        <Typography>
          Bitte wählen Sie eine Buchung aus, um die Details einzusehen.
        </Typography>
      </Box>
    );

  return (
    <Grid container>
      {FIELD_TABS.map(([key, length]) => (
        <Grid item xs={6} key={key} sx={styles.header}>
          <Typography variant="h6" sx={styles.title}>
            {FIELD_TITLES[key]}
          </Typography>
          {length > 1 ? (
            <ToggleButtonGroup
              color="primary"
              value={tab[key]}
              exclusive
              onChange={(_, value) =>
                value !== null &&
                setTab((tab) => ({
                  ...tab,
                  [key]: value,
                }))
              }
              sx={styles.tabButtons}
            >
              {Array.from({ length }, (_, i) => (
                <ToggleButton value={i} key={i}>
                  {i + 1}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          ) : null}
        </Grid>
      ))}
      {MAPPED_FIELD_NAMES.map(([key, value]) => (
        <Grid xs={6} item key={key}>
          {value[tab[key]].map(([subKey, subValue]) => (
            <TextFieldWrapper name={subValue + ":"} key={subKey}>
              <TextField
                value={
                  props.attributes
                    ? props.attributes[key][tab[key]][subKey]
                    : ""
                }
                multiline
                maxRows={3}
                onChange={(e) => {
                  if (props.attributes) {
                    // Build new attributes array
                    const newAttributes = [...props.attributes[key]];
                    newAttributes[tab[key]] = {
                      ...newAttributes[tab[key]],
                      [subKey]: e.target.value,
                    };

                    props.onChange({
                      ...props.attributes,
                      [key]: newAttributes,
                    });
                  }
                }}
                fullWidth
              />
            </TextFieldWrapper>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

export function DisplayCustomerAttributes(
  props: DisplayCustomerAttributesProps
) {
  const [tab, setTab] = React.useState(
    // Set current tabs to the first tab
    Object.fromEntries(FIELD_TABS.map(([key]) => [key, 0])) as {
      [key in keyof AppointmentAttributes]: number;
    }
  );

  const attributes = React.useMemo(() => {
    return props.attributes
      ? (
          (
            Object.entries(props.attributes) as [
              keyof AppointmentAttributes,
              any,
            ][]
          ).map(([key, value]) => [
            key,
            value
              .map((v: { [key: string]: string }) =>
                Object.entries(v)
                  // Filter empty attributes
                  .filter(([_, value]) => value)
              )
              // Filter empty tabs
              .filter((value: any) => value.length > 0),
          ]) as [
            keyof AppointmentAttributes,
            [
              keyof AppointmentAttributes[keyof AppointmentAttributes][number],
              string,
            ][][],
          ][]
        )
          // Filter empty categories
          .filter(([_, value]) => value.length > 0)
          // Filter faulty attributes
          .filter(([key]) => FIELD_NAMES[key])
      : [];
  }, [props.attributes]);

  return (
    <Box>
      {attributes.map(([key, value]) => (
        <React.Fragment key={key}>
          <Box position="relative" display="flex" alignItems="center">
            <Typography variant="h6">{FIELD_TITLES[key]}</Typography>
            {value.length > 1 ? (
              <ToggleButtonGroup
                value={tab[key]}
                exclusive
                onChange={(_, value) =>
                  value !== null &&
                  setTab((tab) => ({
                    ...tab,
                    [key]: value,
                  }))
                }
                size="small"
                sx={styles.tabButtonsOverwrite}
              >
                {Array.from({ length: value.length }, (_, i) => (
                  <ToggleButton
                    value={i}
                    key={i}
                    sx={styles.toggleButtonOverwrite}
                  >
                    {i + 1}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            ) : null}
          </Box>

          {value[tab[key]].map(([subKey, subValue]) => (
            <Typography key={subKey}>{`${
              FIELD_NAMES[key][tab[key]][subKey]
            }: ${subValue}`}</Typography>
          ))}
        </React.Fragment>
      ))}
    </Box>
  );
}
