import { Box, Divider, Typography } from "@mui/material";
import Loading from "../../Loading/Loading";
import ActivityLogServer from "../../Server/ActivityLog/ActivityLogServer";
import {
  ActivityLogType,
  ServiceActivityLog,
} from "../../Server/ActivityLog/ActivityLogTypes";
import GenericLogDisplay from "./GenericLogDisplay";
import { stringifyPrice } from "../../../Functions/functions";
import { useServer } from "../../Server/ServerContext";

export interface ServiceLogDetailsProps {
  log: ServiceActivityLog;
}

export default function ServiceLogDetails(props: ServiceLogDetailsProps) {
  const { settings } = useServer();
  const { data: full_service, isSuccess } = ActivityLogServer.use(
    props.log.id,
    props.log.type as ActivityLogType.services
  );

  if (!isSuccess) return <Loading />;

  return (
    <GenericLogDisplay
      data={[
        {
          name: "Name",
          type: "string",
          data: props.log.name,
        },
        {
          name: "Aktiviert",
          type: "boolean",
          data: props.log.enabled,
        },
        {
          name: "Buchbar",
          type: "boolean",
          data: props.log.bookable,
        },
        {
          name: "Kategorie",
          type: "string",
          data: props.log.category_name,
        },
        {
          name: "Zusatzservice",
          type: "boolean",
          data: props.log.is_additional_service,
        },
        {
          name: "Arbeitsschritte",
          type: "string",
          data: full_service!.tasks.map((task, i, tasks) => (
            <Box key={task.name}>
              <Typography fontWeight="bold">{task.name}</Typography>
              <Typography>
                Erlaubte Mitarbeiter:{" "}
                {task.allowed_employees
                  .map(
                    (e) =>
                      `${e.employee_name} (${stringifyPrice(e.price, settings.getKey("currency_sign"))})`
                  )
                  .join(", ") || <em>keine</em>}
              </Typography>
              {Boolean(task.preparation_time) && (
                <Typography>{task.preparation_time}min Vorbereitung</Typography>
              )}
              {Boolean(task.follow_up_time) && (
                <Typography>{task.follow_up_time}min Nachbereitung</Typography>
              )}
              <Typography>
                {Array.from({ length: task.durations.length / 2 })
                  .map(
                    (_, i) =>
                      task.durations[i * 2] +
                      "min - " +
                      task.durations[i * 2 + 1] +
                      "min"
                  )
                  .join(", ")}
              </Typography>
              {i !== tasks.length - 1 && <Divider />}
            </Box>
          )),
        },
      ]}
    />
  );
}
