// if we login with a store account we first get a jwt for the store account and then for the employee account
// we store the jwt for the store account to use when the employee logs out
let storeJWT = "";
export let jwt = "";
export function setJwt(newJwt: string, wasStoreJWT = false) {
  if (wasStoreJWT) {
    storeJWT = jwt;
  } else {
    storeJWT = "";
  }
  //@ts-ignore
  window.jwt = {
    jwt: newJwt,
    storeJWT,
  };
  jwt = newJwt;
}
export function resetJWTtoStore() {
  console.log("resetting jwt to store");
  setJwt(storeJWT);
}
