import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import StatsServer from "../../Server/Stats/StatsServer";
import { EqualityCheck, SimpleDate } from "@idot-digital/calendar-api";
import React from "react";
import Loading from "../../Loading/Loading";
import { ArrowDropDown, ArrowDropUp, Error } from "@mui/icons-material";
import EmployeeServer from "../../Server/Employees/EmployeeServer";
import { stringifyPrice } from "../../../Functions/functions";
import { useServer } from "../../Server/ServerContext";
import { Styles } from "../../../Types";
import TimePeriodPicker from "../../Settings/Employees/TimePeriodPicker";

const styles = {
  sideWrapper: {
    flex: "0 0 auto",
    margin: (theme) => theme.spacing(2),
    marginTop: (theme) => theme.spacing(8),
  },
  button: {
    padding: (theme) => theme.spacing(1, 2),
    minWidth: "12.5rem",
    height: "3rem",
    marginTop: (theme) => theme.spacing(4),
  },
} satisfies Styles;

export default function EmployeeStats() {
  const { settings } = useServer();
  const [timePeriod, setTimePeriod] = React.useState(
    StatsServer.getFilterDuration()
  );
  React.useEffect(() => {
    StatsServer.setCurrentFilterDuration(timePeriod);
  }, [timePeriod]);

  const {
    data: [total, ...stats] = [],
    isLoading,
    error,
  } = StatsServer.useEmployeeStats(timePeriod.start, timePeriod.end);
  const { data: employees } = EmployeeServer.useAll();

  const [sortBy, setSortBy] = React.useState<
    | "name"
    | "factor_avg"
    | "revenue"
    | "service_factor"
    | "appointment_count"
    | "revenue_per_appointment"
  >("name");
  const [sortDirection, setSortDirection] = React.useState<"ASC" | "DESC">(
    "ASC"
  );

  const preparedStats = React.useMemo(() => {
    const mapped =
      stats?.map((stat) => ({
        ...stat,
        revenue_per_appointment: stat.revenue / stat.appointment_count,
        factor_avg: stat.service_factor / stat.appointment_count,
        name:
          employees?.find((e) => e.id === stat.employeeid)?.shortName ||
          "Unbekannt",
      })) || [];
    const missingEmployees =
      employees?.filter((e) => !mapped.find((m) => m.employeeid === e.id)) ||
      [];
    return [
      ...mapped,
      ...missingEmployees.map((e) => ({
        employeeid: e.id,
        name: e.shortName,
        revenue: 0,
        service_factor: 0,
        appointment_count: 0,
        revenue_per_appointment: 0,
        factor_avg: 0,
      })),
    ].sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      if (aValue < bValue) return sortDirection === "ASC" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "ASC" ? 1 : -1;
      return 0;
    });
  }, [stats, sortBy, sortDirection, employees]);

  if (isLoading) return <Loading />;
  if (error)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={2}
        p={4}
      >
        <Error fontSize="large" color="error" />
        <Typography variant="h4">
          Beim Laden der Daten ist ein Fehler aufgetreten.
        </Typography>
      </Box>
    );

  return (
    <Box display="flex">
      <TableContainer
        sx={{
          p: 4,
          height: (theme) => `calc(100% - ${theme.spacing(4)} * 2)`,
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {(
                [
                  {
                    label: "Name",
                    key: "name",
                    width: 29,
                  },

                  {
                    label: "Umsatz",
                    key: "revenue",
                    width: 10,
                  },
                  {
                    label: "Dienstleistungsfaktor",
                    key: "service_factor",
                    width: 18,
                  },
                  {
                    label: "Termine",
                    key: "appointment_count",
                    width: 10,
                  },
                  {
                    label: "Faktor pro Termin",
                    key: "factor_avg",
                    width: 16,
                  },
                  {
                    label: "Umsatz pro Termin",
                    key: "revenue_per_appointment",
                    width: 17,
                  },
                ] as const
              ).map((header, i) => (
                <TableCell
                  key={header.key + i}
                  sx={{
                    cursor: "pointer",
                    fontWeight: sortBy === header.key ? "bold" : undefined,
                    width: `${header.width}%`,
                    userSelect: "none",
                  }}
                  onClick={() => {
                    if (sortBy === header.key) {
                      setSortDirection(
                        sortDirection === "ASC" ? "DESC" : "ASC"
                      );
                    } else {
                      setSortBy(header.key);
                      setSortDirection("ASC");
                    }
                  }}
                >
                  {header.label}
                  {sortBy === header.key &&
                    (sortDirection === "ASC" ? (
                      <ArrowDropDown sx={{ verticalAlign: "middle" }} />
                    ) : (
                      <ArrowDropUp sx={{ verticalAlign: "middle" }} />
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {preparedStats.map((stat) => (
              <TableRow
                key={stat.employeeid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {stat.name}
                </TableCell>

                <TableCell>
                  {stringifyPrice(
                    stat.revenue,
                    settings.getKey("currency_sign")
                  )}
                </TableCell>
                <TableCell>{stat.service_factor}</TableCell>
                <TableCell>{stat.appointment_count}</TableCell>
                <TableCell>{stat.factor_avg.toFixed(1)}</TableCell>
                <TableCell>
                  {stringifyPrice(
                    stat.revenue_per_appointment,
                    settings.getKey("currency_sign")
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                Salon gesamt
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {stringifyPrice(
                  total.revenue,
                  settings.getKey("currency_sign")
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {total.service_factor}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {total.appointment_count}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {(
                  total.service_factor / (total.appointment_count || 1)
                ).toFixed(1)}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {stringifyPrice(
                  total.revenue / (total.appointment_count || 1),
                  settings.getKey("currency_sign")
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={styles.sideWrapper}>
        <TimePeriodPicker
          period={timePeriod}
          onChange={(period) => {
            const start = period.start.toDate();
            start.setHours(0, 0, 0, 0);
            const end = period.end.toDate();
            end.setHours(23, 59, 59, 999);
            setTimePeriod({
              start: SimpleDate.fromDate(start),
              end: SimpleDate.fromDate(end),
            });
          }}
          error={
            timePeriod.start.isEqual(timePeriod.end) !== EqualityCheck.later
          }
          variant="vertical"
        />
      </Box>
    </Box>
  );
}
